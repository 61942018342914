import * as EmailValidator from "email-validator"
import { isEmpty, trimEnd } from "lodash"
import { FormattedMessage } from "react-intl"
import DOMPurify from "dompurify"
import React from "react"
import isURL from "is-url"
import striptags from "striptags"

import { EMPTY_HTML } from "../../globals"

const getValidationMessage = (validatorKey) => (
  <FormattedMessage id={`form.validation.${validatorKey}`} />
)
const stripHtml = (html) => striptags(DOMPurify.sanitize(html))
const digitRegex = /(?:\d.*?){4,}/
const decimalRegex = /^[1-9]+[0-9]*\.?[0-9]*$/

export const required = (value) =>
  (value || typeof value === "number") && trimEnd(value) !== EMPTY_HTML && value !== ""
    ? null
    : getValidationMessage("required")
export const mustBeUrl = (value) =>
  value && isURL(value) && !value.includes("@") ? null : getValidationMessage("mustBeUrl")
export const mustBeEmail = (value) =>
  EmailValidator.validate(value) ? null : getValidationMessage("mustBeEmail")
export const mustBePhoneNumber = (value) =>
  value.startsWith("tel:") && !value.includes("@") && digitRegex.test(value)
    ? null
    : getValidationMessage("mustBePhoneNumber")
export const maxLength = (max) => (value) =>
  value.length >= max ? null : (
    <FormattedMessage id="app.form.validation.maxLength" value={{ maxLength: () => max }} />
  )
export const maxHtmlLength = (max) => (value) => maxLength(max)(stripHtml(value))

export const mustBeDecimal = (value) =>
  decimalRegex.test(value) ? null : getValidationMessage("mustBeDecimal")

export const mustBeValidCustomLink = (value) => {
  const protocol = value.startsWith("http")
    ? value.split("://")[0] + "://"
    : value.startsWith("tel:")
    ? "tel:"
    : "mailto:"
  switch (protocol) {
    case "http://":
    case "https://":
      return isEmpty(value.split("://")[1]) ? getValidationMessage("required") : mustBeUrl(value)
    case "mailto:":
      return value && !value.startsWith("http") && !value.startsWith("tel:")
        ? mustBeEmail(value)
        : getValidationMessage("mustBeEmail")
    case "tel:":
      return isEmpty(value.split("tel:")[1])
        ? getValidationMessage("required")
        : mustBePhoneNumber(value)
    default: // fallthrough
  }
}

import { Form, Button } from "antd"
import React, { lazy, useCallback, useEffect } from "react"
import { change, getFormValues, propTypes, reduxForm } from "redux-form"
import { Scrollbars } from "react-custom-scrollbars"
import { useSelector } from "react-redux"

import actions from "src/store/actions"
import useActions from "src/hooks/useActions"
import StepNavigators from "src/components/StepNavigators"

const form = "setOrderForm"
const SetOrderStep = lazy(() => /* webpackPrefetch: 1 */ import("../steps/SetOrderStep"))

SetOrderForm.propTypes = {
  ...propTypes,
}

export function SetOrderForm(props) {
  const [changeExternalForm, closeModal] = useActions([change, actions.uiActions.closeFormModal])
  const { handleSubmit, item, initialize } = props
  const formValues = useSelector(getFormValues(form))
  const procedureValues = useSelector(getFormValues("procedureForm"))

  useEffect(() => {
    initialize({
      item: item,
      newOrder: procedureValues.newOrder ? procedureValues.newOrder : item.steps,
    })
    // eslint-disable-next-line
  }, [])

  const submitNewOrder = useCallback(() => {
    // TODO: perhaps make external form to update an option passed via. props
    changeExternalForm("procedureForm", "steps", formValues.newOrder)
    closeModal()
  }, [formValues, changeExternalForm, closeModal])

  return (
    <>
      <Form className="ant-form">
        <Scrollbars autoHide hideTracksWhenNotNeeded>
          <div className="ant-form__spacing">
            <SetOrderStep parentItem={formValues ? { ...item, ...formValues } : item} />
          </div>
        </Scrollbars>
      </Form>
      <StepNavigators className="row">
        <div className="col-xs end-xs">
          <Button htmlType="button" onClick={handleSubmit(submitNewOrder)} type="primary">
            Confirm
          </Button>
        </div>
      </StepNavigators>
    </>
  )
}

const ConnectedSetOrderForm = reduxForm({ form })(SetOrderForm)
export default ConnectedSetOrderForm

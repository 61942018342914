import { CloseOutlined, UpOutlined } from "@ant-design/icons"
import { Avatar, Button, Card, Divider, Tooltip } from "antd"
import { Field, FormSection } from "redux-form"
import { FormattedMessage, useIntl } from "react-intl"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React from "react"

import { FileDragAndDropField, InputField } from "src/inputs/connected-input-fields"
import { getFileNameFromUrl, getIcon } from "src/components/helpers"
import FormattedTitle from "src/components/FormattedTitle"
import { OpfItemShape } from "src/components/opf-prop-types"

import "./style.less"

DocumentsStep.propTypes = {
  item: OpfItemShape,
  parentItem: OpfItemShape,
  documents: PropTypes.arrayOf(PropTypes.object),
  customUploadRequest: PropTypes.func,
  removeFile: PropTypes.func,
  mapToMediaLinks: PropTypes.func,
  multiple: PropTypes.bool,
}

export default function DocumentsStep({
  documents,
  customUploadRequest,
  removeAttachment,
  moveAttachmentUp,
  multiple,
  acceptedFileExtensions,
}) {
  const singleUpload = multiple === false

  const documentFileExtensions = ["oft", "pdf", "doc", "docx", "dot", "odt", "msg"]
  const tableFileExtensions = ["csv", "xls", "xlsx", "xlsb", "xlsm"]
  const slideFileExtensions = ["ppt", "pptx"]
  const fileExtensions = acceptedFileExtensions ?? [
    ...documentFileExtensions,
    ...tableFileExtensions,
    ...slideFileExtensions,
  ]

  return (
    <div className="fade-in">
      <Card
        title={
          singleUpload ? (
            <FormattedTitle id={"form.step.addDocument.title"} />
          ) : (
            <FormattedTitle
              id={"form.step.addDocuments.title"}
              values={{ one: () => "", plural: (chunk) => chunk }}
            />
          )
        }
        bordered={false}
      >
        {singleUpload ? (
          <FormattedMessage id="form.step.addDocument.description" />
        ) : (
          <FormattedMessage id="form.step.addDocuments.description" />
        )}
      </Card>
      <Field
        name="documents"
        component={FileDragAndDropField}
        acceptedFileExtensions={fileExtensions}
        customUploadRequest={customUploadRequest}
        multiple={multiple}
      />
      {!isEmpty(documents) && (
        <>
          <FormSection name="documentNames">
            <ul className="link-fields-container link-fields-container--documents">
              {documents.map((document, index) => (
                <DocumentField
                  key={index}
                  document={document}
                  index={index}
                  removeAttachment={removeAttachment}
                  moveAttachmentUp={moveAttachmentUp}
                  multiple={multiple}
                />
              ))}
            </ul>
          </FormSection>
          <Divider />
        </>
      )}
    </div>
  )
}

const DocumentField = ({ document, index, removeAttachment, moveAttachmentUp, multiple }) => {
  const intl = useIntl()
  const { url, uid, name, response} = document
  const isTopField = index === 0

  const fileName = response != null ? response.media.name : name;
  return (
    <li key={uid} className="fade-in-down">
      <div className="row middle-xs link-details" id={uid}>
        <div className="col-xs-1 center-xs">
          <Tooltip placement="topLeft" title={getFileNameFromUrl(url)}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <Avatar shape="square">{getIcon(url, { isTwoTone: true })}</Avatar>
            </a>
          </Tooltip>
        </div>
        <div className="col-xs">
          <Field name={`${uid}.name`} component={InputField} placeholder={fileName} value={fileName} disableValidation />
        </div>
        <div className="center-xs">
          {multiple !== false && (
            <span style={{ marginRight: "30px" }}>
              <Tooltip placement="topRight" title={intl.formatMessage({ id: "common.moveUp" })}>
                <Button
                  type="dashed"
                  shape="circle"
                  icon={<UpOutlined />}
                  onClick={() => {
                    moveAttachmentUp("documents", index)
                  }}
                  disabled={isTopField}
                />
              </Tooltip>
            </span>
          )}

          <Tooltip
            placement="topRight"
            title={intl.formatMessage({ id: "common.remove" }, { target: () => "file" })}
            key={`document-remove_${uid}`}
          >
            <Button
              type="dashed"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => removeAttachment("documents", uid)}
            />
          </Tooltip>
        </div>
      </div>
    </li>
  )
}

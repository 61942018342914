import { CloseOutlined, UpOutlined } from "@ant-design/icons"
import { Button, Card, Divider, Tooltip } from "antd"
import { FormattedMessage, useIntl } from "react-intl"
import { Field } from "redux-form"
import PropTypes from "prop-types"
import React from "react"
import { isEmpty } from "lodash"

import { FileDragAndDropField } from "src/inputs/connected-input-fields"
import FormattedTitle from "src/components/FormattedTitle"

PicturesStep.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  customUploadRequest: PropTypes.func,
  removeFile: PropTypes.func,
}

export default function PicturesStep({
  mediaFiles,
  removeAttachment,
  moveAttachmentUp,
  customUploadRequest,
}) {
  const acceptedFileTypes = "image/png,image/jpg,image/jpeg,image/gif"

  return (
    <div className="fade-in">
      <Card title={<FormattedTitle id="form.step.addImages.title" />} bordered={false}>
        <FormattedMessage
          id="form.step.addImages.description"
          values={{
            br: () => <br />,
          }}
        />
      </Card>
      <Field
        name="mediaFiles"
        component={FileDragAndDropField}
        customUploadRequest={customUploadRequest}
        acceptedFileType={acceptedFileTypes}
      />
      {!isEmpty(mediaFiles) && (
        <>
          <ul className="link-fields-container link-fields-container--documents">
            {mediaFiles.map((mediaFile, index) => (
              <PictureField
                key={index}
                mediaFile={mediaFile}
                index={index}
                removeAttachment={removeAttachment}
                moveAttachmentUp={moveAttachmentUp}
              />
            ))}
          </ul>
          <Divider />
        </>
      )}
    </div>
  )
}

const PictureField = ({ mediaFile, index, removeAttachment, moveAttachmentUp }) => {
  const intl = useIntl()
  const { url, uid, name, thumbUrl } = mediaFile
  const isTopField = index === 0

  const fileListType = "mediaFiles"

  return (
    <li key={`${uid}_${index}`} className="fade-in-down">
      <div className="row middle-xs link-details" id={uid}>
        <div className="link-fields-container__image-placeholder">
          <div
            className="link-fields-container__image"
            style={{ backgroundImage: `url(${thumbUrl || url})` }}
          />
        </div>
        <div className="col-xs link-details__file-name">{name}</div>
        <div className="center-xs">
          <span style={{ marginRight: "30px" }}>
            <Tooltip
              placement="topRight"
              title={intl.formatMessage({ id: "common.moveUp" })}
              key={`remove-image_${uid}`}
            >
              <Button
                type="dashed"
                shape="circle"
                icon={<UpOutlined />}
                onClick={() => moveAttachmentUp(fileListType, index)}
                disabled={isTopField}
              />
            </Tooltip>
          </span>

          <Tooltip
            placement="topRight"
            title={intl.formatMessage({ id: "common.remove" }, { target: () => "image" })}
            key={`remove-image_${uid}`}
          >
            <Button
              type="dashed"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => removeAttachment(fileListType, uid)}
            />
          </Tooltip>
        </div>
      </div>
    </li>
  )
}

import { Card, Select, TreeSelect } from "antd"
import { Field } from "redux-form"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty } from "lodash"

import { OPF_DOCUMENT_TYPES, OPF_ITEM_TYPES } from "../../../../globals"
import { SelectField, TreeSelectField } from "src/inputs/connected-input-fields"
import { bindActions, bindSelectors } from "src/store/utils"

import unflattenTree from "src/store/utils/unflattenTree"
import { getDocumentSuperType } from "src/components/helpers"
import FormattedTitle from "src/components/FormattedTitle"
import selectors from "src/store/selectors"
import { OpfDocument, OpfItem } from "src/types/global"

const { Option } = Select

type RelationsStepProps = {
  itemsGroupedByType: { type: string; children: OpfItem[] }[]
  cardTitle: string | React.ReactNode
  cardDescription: React.ReactNode
  documentsForTree: OpfDocument[]
}

export function RelationsStep(props: RelationsStepProps) {
  const { itemsGroupedByType, cardTitle, cardDescription, documentsForTree } = props

  const isTreeNodeDisabled = (document: OpfDocument, documents: OpfDocument[]): boolean => {
    const isLeaf = getDocumentSuperType(document.type) === OPF_DOCUMENT_TYPES.DOCUMENT
    const children = documents.filter((d) => d.parentId === document.opfId)
    const hasDisabledChildren = children.every((child) => isTreeNodeDisabled(child, documents))
    return !isLeaf && (children.length === 0 || hasDisabledChildren)
  }

  const processes = itemsGroupedByType.filter((item) => item.type === OPF_ITEM_TYPES.PROCESS)

  const documentsWithTreeData = documentsForTree.map((document) => ({
    ...document,
    key: document.opfId,
    value: document.opfId,
    disabled: isTreeNodeDisabled(document, documentsForTree),
  }))
  const documentsAsTreeNodes = unflattenTree(documentsWithTreeData)

  return (
    <>
      <br />
      {(cardTitle || cardDescription) && (
        <Card title={cardTitle} bordered={false}>
          {cardDescription}
        </Card>
      )}
      <Field
        name="relatedDocuments"
        component={TreeSelectField}
        onBlur={(event: React.FocusEvent) => event.preventDefault()}
        treeCheckable
        multiple
        loading={isEmpty(documentsAsTreeNodes)}
        disabled={isEmpty(documentsAsTreeNodes)}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        treeData={documentsAsTreeNodes}
        placeholder="Select Related Policies / Standards"
        label={
          <FormattedTitle
            id="common.relatedDocuments"
            values={{ one: () => "", plural: (chunk: React.ReactNode[]) => chunk }}
          />
        }
      />
      <Field
        name="relatedProcesses"
        component={SelectField}
        placeholder="Select Related Processses"
        mode="multiple"
        label={
          <FormattedTitle
            id="common.relatedProcess"
            values={{ one: () => "", plural: (chunk: React.ReactNode[]) => chunk }}
          />
        }
      >
        {processes[0].children.map((processItem) => (
          <Option value={processItem.opfId} key={processItem.opfId}>
            {processItem.title}
          </Option>
        ))}
      </Field>
    </>
  )
}

RelationsStep.propTypes = {
  cardTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cardDescription: PropTypes.object,
  getAllItems: PropTypes.arrayOf(PropTypes.object),
  itemsGroupedByType: PropTypes.arrayOf(PropTypes.object),
  fetchDocumentCategories: PropTypes.func,
}
const mapStateToProps = bindSelectors({
  formMode: selectors.uiSelectors.getFormMode,
  documentCategories: selectors.documentsSelectors.getAllRelationCategories,
  getAllRelatedChildDocuments: selectors.documentsSelectors.getAllRelatedChildDocuments,
  itemsGroupedByType: selectors.itemSelectors.getItemsGroupedByType,
  documentsForTree: selectors.documentsSelectors.getDocumentsForCurrentTree,
})
const mapDispatchToProps = bindActions({})
export default connect(mapStateToProps, mapDispatchToProps)(RelationsStep)

import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import { FileDragAndDropField } from "src/inputs/connected-input-fields"
import Thumbnail from "./Thumbnail"

import "./style.less"

export default function FormMediaWall({
  fileList,
  item,
  customUploadRequest,
  handlePreview,
  removeFile,
}) {
  return (
    <div className="form-media-wall">
      <div className="ant-form-item-label">
        <label>
          <FormattedMessage id="form.notification.attachImage" />
        </label>
      </div>
      <div className="form-media-wall__thumbnails">
        {fileList?.map((file, index) => (
          <Thumbnail
            key={index}
            file={file}
            handlePreview={handlePreview}
            removeFile={removeFile}
          />
        ))}
        <Field
          name="image"
          className="form-media-wall__upload-field"
          component={FileDragAndDropField}
          customUploadRequest={customUploadRequest}
          acceptedFileType="image/png,image/jpg,image/jpeg,image/gif"
          limit={1}
          multiple={false}
          item={item}
        />
      </div>
    </div>
  )
}

import { Field, getFormValues, propTypes, reduxForm } from "redux-form"
import { FormattedMessage, injectIntl } from "react-intl"
import { Component, lazy } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { notification } from "antd"
import { pick } from "lodash"

import { bindActions, bindSelectors } from "src/store/utils"
import { ACTIONS, EMPTY_HTML, OPF_ITEM_TYPES } from "../../globals"
import { DateField } from "src/inputs/connected-input-fields"
import { OpfItemShape } from "src/components/opf-prop-types"
import SelectItemTypeStep from "../steps/SelectItemTypeStep"
import StepHandler from "../StepHandler"
import TitleAndDescriptionStep from "../steps/TitleAndDescriptionStep"
import actions from "src/store/actions"
import { getItemTypeChildTypes } from "../../services/itemTypes"
import selectors from "src/store/selectors"
import { InputField } from "src/inputs/connected-input-fields"
import { mustBeDecimal } from "src/forms/validators"

const MetadataStep = lazy(() => /* webpackPrefetch: 1 */ import("../steps/MetadataStep"))
const SetOrderStep = lazy(() => /* webpackPrefetch: -1 */ import("../steps/SetOrderStep"))
const ReviewStep = lazy(() => /* webpackPrefetch: -2 */ import("../steps/ReviewStep"))
const TranslateStep = lazy(() => /* webpackPrefetch: -3 */ import("../steps/TranslateStep"))

const itemFields = ["type", "title", "viewableByExternals", "nextReviewDate", "text", "icon"]

const onSubmit = (values, dispatch, props) => {
  const { mode, create, update/**, createVariant */, translate, reorder, closeModal, intl } = props
  const { newOrder } = values
  const currentItem = props.item
  const formData = {
    ...pick(values, itemFields),
    slug: currentItem.slug,
    opfId: currentItem.opfId,
    // variantId: currentItem.variantId ? currentItem.variantId : null,
  }
  let submission
  switch (mode) {
    case ACTIONS.ADD:
      submission = create({ opfItem: { ...formData, parentId: currentItem.opfId } }).then(
        ({ opfItem }) => {
          if (newOrder === undefined) return Promise.resolve()
          const replacedNewWithCreatedItem = newOrder.map((item) =>
            item.new ? { ...opfItem, order: item.order } : item
          )
          return reorder({ opfItems: replacedNewWithCreatedItem })
        }
      )
      break
    case ACTIONS.EDIT:
      submission = Promise.all([
        update({ opfItem: { ...currentItem, ...formData } }),
        newOrder ? reorder({ opfItems: newOrder }) : Promise.resolve(),
      ])
      break
    // case ACTIONS.CREATE_VARIANT:
    //   submission = createVariant({ opfItem: { ...currentItem, ...formData } })
    //   break
    case ACTIONS.TRANSLATE:
      submission = translate({ opfItem: { ...currentItem, ...formData } })
      break
    default:
      submission = Promise.resolve()
      break
  }
  return submission.then(closeModal).then(() => {
    const message = intl.formatMessage({ id: "message.savedChanges" })
    notification.success({ message })
  })
}

const ProcedureMetaFields = injectIntl((props) => {
  const { intl, item, ...rest } = props
  return (
    <div>
      <MetadataStep {...rest}>
        <div className="col-xs-12 col-sm-6">
          <div className="row form-wizard__section">
            <div className="ant-form-item-label ant-form-item-label--fluid">
              <span>
                <FormattedTitle id="common.editorialVersion" />
              </span>
            </div>
            <Field
              name="editorialVersion"
              component={InputField}
              validate={[mustBeDecimal]}
              disabled
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
          <div className="row form-wizard__section">
            <div className="ant-form-item-label ant-form-item-label--fluid">
              <span>
                <FormattedTitle id="common.documentOwnerEmailAddress" />
              </span>
            </div>
            <Field
              name="documentOwner"
              component={InputField}
              disabled
            />
          </div>
        </div>      
      </MetadataStep>
    </div>
  )
})

export class ProcessForm extends Component {
  validate = () => [...itemFields, "newOrder"].forEach(this.props.touch)

  getAddSteps = () => {
    const { item, formValues } = this.props
    const childTypes = getItemTypeChildTypes(item.type)
    const newItem = {
      new: true,
      ...pick(formValues, itemFields),
    }
    const addSteps = [
      {
        title: "form.step.titleAndDescription.title",
        component: TitleAndDescriptionStep,
        props: {
          itemType: newItem.type,
          cardTitle: <FormattedMessage id="form.step.titleAndDescription.eng.title" />,
          cardDescription: (
            <FormattedMessage
              id="form.step.titleAndDescription.eng.description"
              values={{ itemType: () => newItem.type }}
            />
          ),
        },
      },
      {
        title: "form.step.setMetadata.title",
        component: MetadataStep,
      },
      {
        title: "form.step.setOrder.title",
        component: SetOrderStep,
        props: { parentItem: item, newItem },
      },
    ]
    if (childTypes.length > 1) {
      addSteps.unshift({
        title: "form.step.selectType.title",
        component: SelectItemTypeStep,
        props: { item },
      })
      if (formValues && formValues.type === OPF_ITEM_TYPES.PROCEDURE) {
        addSteps[2].component = ProcedureMetaFields
      }
    }
    return addSteps
  }

  getAddProcedureSteps = () => {
    const { item } = this.props
    const addProcedureSteps = [
      {
        title: "form.step.titleAndDescription.title",
        component: TitleAndDescriptionStep,
        props: {
          item,
          cardTitle: null,
          cardDescription: null,
        },
      },
    ]
    return addProcedureSteps
  }

  getEditSteps = () => {
    const { item, getUserItemRights } = this.props
    const userItemRights = getUserItemRights(item)
    const editSteps = [
      {
        title: "form.step.titleAndDescription.title",
        component: TitleAndDescriptionStep,
        props: {
          item,
          cardTitle: null,
          cardDescription: null,
        },
      },
      {
        title: "form.step.setMetadata.title",
        component: MetadataStep,
        props: { item },
      },
    ]
    if (userItemRights.updateOrder) {
      editSteps.push({
        title: "form.step.setOrder.title",
        component: SetOrderStep,
        props: { parentItem: item },
      })
    }
    return editSteps
  }

  // getCreateVariantSteps = () => {
  //   const { item, intl } = this.props
  //   const intlVariant = intl.formatMessage(
  //     { id: "common.variant" },
  //     { one: (chunk) => chunk, plural: () => "" }
  //   )
  //   return [
  //     {
  //       title: "form.step.titleAndDescription.title",
  //       component: TitleAndDescriptionStep,
  //       props: {
  //         item,
  //         cardTitle: <FormattedMessage id="form.step.titleAndDescription.eng.title" />,
  //         cardDescription: (
  //           <FormattedMessage
  //             id="form.step.titleAndDescription.eng.description"
  //             values={{ itemType: () => intlVariant }}
  //           />
  //         ),
  //       },
  //     },
  //     {
  //       title: "form.step.setMetadata.title",
  //       component: MetadataStep,
  //       props: { item },
  //     },
  //   ]
  // }

  componentDidMount() {
    const { item, initialize, change, mode } = this.props
    if (mode === ACTIONS.ADD) {
      const childTypes = getItemTypeChildTypes(item.type)
      if (childTypes.length === 1) {
        const [childType] = childTypes
        change("type", childType)
      }
      change("viewableByExternals", false)
    }

    if (item && (mode === ACTIONS.EDIT/** || mode === ACTIONS.CREATE_VARIANT */)) {
      const values = pick(item, itemFields)
      initialize({
        ...values,
        text: item.text === "" ? EMPTY_HTML : item.text,
      })
    }
  }

  render() {
    const { item, valid, mode, formValues, submit, submitting, intl } = this.props
    let steps = []
    switch (mode) {
      case ACTIONS.TRANSLATE:
        steps = [
          {
            title: "form.step.translate.title",
            component: TranslateStep,
            props: { item },
          },
        ]
        break
      case ACTIONS.ADD:
        steps = this.getAddSteps()
        break
      case ACTIONS.ADD_PROCEDURE:
        steps = this.getAddProcedureSteps()
        break
      case ACTIONS.EDIT:
        steps = this.getEditSteps()
        break
      // case ACTIONS.CREATE_VARIANT:
      //   steps = this.getCreateVariantSteps()
      //   break
      default: // fallthrough
    }
    if ([ACTIONS.ADD/**, ACTIONS.CREATE_VARIANT */, ACTIONS.EDIT].includes(mode)) {
      steps = [
        ...steps,
        {
          title: "form.step.review.title",
          component: ReviewStep,
          props: { value: formValues, intl: intl },
        },
      ]
    }
    return (
      <StepHandler
        onFinalStepSubmit={submit}
        steps={steps}
        overview={mode !== ACTIONS.TRANSLATE}
        valid={valid}
        validate={this.validate}
        submitting={submitting}
      />
    )
  }
}

ProcessForm.propTypes = {
  mode: PropTypes.string,
  item: OpfItemShape,
  formValues: PropTypes.object,
  getUserItemRights: PropTypes.func,
  create: PropTypes.func,
  update: PropTypes.func,
  // createVariant: PropTypes.func,
  translate: PropTypes.func,
  closeModal: PropTypes.func,
  ...propTypes,
}
const form = "process"
const ConnectedProcessForm = reduxForm({ form, onSubmit })(ProcessForm)
const mapStateToProps = bindSelectors({
  formValues: getFormValues(form),
  getUserItemRights: selectors.userSelectors.getUserItemRights,
})
const mapDispatchToProps = bindActions({
  create: actions.itemActions.createItem,
  update: actions.itemActions.updateItem,
  // createVariant: actions.itemActions.createItemVariant,
  translate: actions.itemActions.translateItem,
  reorder: actions.itemActions.reorderItems,
  closeModal: actions.uiActions.closeFormModal,
})
const IntlProcessForm = injectIntl(ConnectedProcessForm)
export default connect(mapStateToProps, mapDispatchToProps)(IntlProcessForm)

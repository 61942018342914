import { FormattedMessage, injectIntl } from "react-intl"
import React, { Component, lazy } from "react"
import { getFormValues, propTypes, reduxForm } from "redux-form"
import { has, pick, uniq, without } from "lodash"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { notification } from "antd"
import titleize from "titleize"

import { ACTIONS, EMPTY_HTML } from "../../globals"
import { bindActions, bindSelectors } from "src/store/utils"
import StepHandler from "../StepHandler"
import actions from "src/store/actions"
import { linkToFile } from "src/components/helpers"
import selectors from "src/store/selectors"
import { getCurrentSlug } from "src/store/utils/routeUtils"

const RelationsStep = lazy(() =>
  /* webpackPrefetch: 1 */ import("../steps/opfDocuments/RelationsStep")
)
const DocumentsStep = lazy(() => /* webpackPrefetch: -1 */ import("../steps/DocumentsStep"))
const LinksStep = lazy(() => /* webpackPrefetch: -2 */ import("../steps/LinksStep"))

const itemFields = [
  "tree",
  "type",
  "title",
  "viewableByExternals",
  "editorialVersion",
  "documentOwner",
  "nextReviewDate",
  "reviewDate",
  "relatedProcesses",
  "relatedDocuments",
  "text",
]

export class DocumentForm extends Component {
  validate = () => [...itemFields, "newOrder"].forEach(this.props.touch)

  getUploadPdfStep = () => {
    const { formValues, mapToMediaLinks, item } = this.props
    const documents =
      formValues && formValues.documents ? mapToMediaLinks(formValues.documents.fileList) : null
    return [
      {
        title: "form.step.uploadPdf.title",
        component: DocumentsStep,
        props: {
          item,
          documents,
          multiple: false,
          cardTitle: (
            <FormattedMessage
              id="form.step.pdf.title"
              values={{ type: () => titleize(item.type) }}
            />
          ),
          cardDescription: (
            <FormattedMessage
              id="form.step.pdf.description"
              values={{ type: () => titleize(item.type) }}
            />
          ),
          removeAttachment: this.removeAttachment,
          moveAttachmentUp: this.moveAttachmentUp,
          type: "uploadFile",
          limit: 1,
          acceptedFileExtensions: ["pdf", "zip", "oft", "msg"],
          customUploadRequest: this.customUploadRequest,
        },
      },
    ]
  }

  getEditRelationsStep = () => {
    const { item } = this.props
    return [
      {
        title: "form.step.relations.title",
        component: RelationsStep,
        props: {
          item,
          cardTitle: <FormattedMessage id="form.step.relations.intro.title" />,
          cardDescription: <FormattedMessage id="form.step.relations.intro.description" />,
        },
      },
    ]
  }

  getEditTools = () => {
    const { item, formValues, mapToMediaLinks } = this.props
    const documents =
      formValues && formValues.documents ? mapToMediaLinks(formValues.documents.fileList) : null
    const editToolsSteps = [
      {
        title: "form.step.editDocuments.title",
        component: DocumentsStep,
        props: {
          item,
          documents,
          customUploadRequest: this.customUploadRequest,
          removeAttachment: this.removeAttachment,
          moveAttachmentUp: this.moveAttachmentUp,
        },
      },
      {
        title: "form.step.editLinks.title",
        component: LinksStep,
        props: { item },
      },
    ]
    return editToolsSteps
  }

  componentDidMount() {
    const { item, initialize, change, mode } = this.props
    if (!item) return
    if (mode === ACTIONS.EDIT_TOOLS || mode === ACTIONS.UPLOAD_PDF) {
      const values = pick(itemFields, item)
      initialize({
        ...values,
        text: item.text === "" ? EMPTY_HTML : item.text,
      })
    }
    if (mode === ACTIONS.EDIT_RELATIONS) {
      initialize({
        relatedProcesses: item.relatedProcesses || [],
        relatedDocuments: item.relatedDocuments || [],
      })
    }
    if (mode === ACTIONS.EDIT_TOOLS) {
      const validLinks = item.links
      if (validLinks && validLinks.length) {
        const internalLinks = validLinks.filter((link) => link.internal)
        const externalLinks = without(validLinks, ...internalLinks)
        const fileList = internalLinks.map(linkToFile).filter(Boolean)
        change("links", externalLinks)
        change("documents", { fileList })
        fileList.forEach((file) => {
          change(`documentNames.${file.uid}.name`, file.name)
        })
      }
    }
    if (mode === ACTIONS.UPLOAD_PDF) {
      const fileList = [item.documentAsFile].map(linkToFile).filter(Boolean)
      change("documents", { fileList })
      fileList.forEach((file) => {
        change(`documentNames.${file.uid}.name`, file.name)
      })
    }
  }

  customUploadRequest = ({ onSuccess, onError, file }) => {
    const { uploadDocumentFile, item } = this.props
    return uploadDocumentFile({ opfDocument: item, file })
      .then((result) => onSuccess(result, result.media))
      .catch(onError)
  }

  removeAttachment = (fileListType, uid) => {
    const { change, formValues } = this.props
    const fileList = formValues[fileListType].fileList.filter((file) => file.uid !== uid)

    change(fileListType, { ...formValues[fileListType], fileList })
  }

  moveAttachmentUp = (fileListType, currentIndex) => {
    if (currentIndex === 0) return null

    const { change, formValues } = this.props

    const fileList = [...formValues[fileListType].fileList]
    const newIndex = currentIndex - 1
    const _file = fileList[newIndex]
    fileList[newIndex] = fileList[currentIndex]
    fileList[currentIndex] = _file

    change(fileListType, { ...formValues[fileListType], fileList })
  }

  render() {
    const { valid, mode, submit, submitting } = this.props
    let steps = []
    switch (mode) {
      case ACTIONS.UPLOAD_PDF:
        steps = this.getUploadPdfStep()
        break
      case ACTIONS.EDIT_RELATIONS:
        steps = this.getEditRelationsStep()
        break
      case ACTIONS.EDIT_TOOLS:
        steps = this.getEditTools()
        break
      default: // fallthrough
    }
    return (
      <StepHandler
        onFinalStepSubmit={submit}
        steps={steps}
        overview={mode !== ACTIONS.EDIT_RELATIONS}
        valid={valid}
        validate={this.validate}
        submitting={submitting}
      />
    )
  }
}

export const onSubmit = (values, dispatch, props) => {
  const {
    mode,
    item,
    closeModal,
    intl,
    updateLinks,
    updateRelatedItems,
    updateDocumentAsFile,
    getChildPoliciesAndStandards,
    getRelatedDocumentById,
    fetchDocument,
    currentLanguage,
  } = props
  const { documentNames } = values
  const links = values.links ? values.links : []
  const documentFiles = has(values, "documents.fileList") ? values.documents.fileList : []
  const getUpdatedDocumentName = (file) => {
    const updatedDocument = documentNames ? documentNames[file.uid] : null
    return updatedDocument ? updatedDocument.name : file.response.media.name
  }

  const documentLinks = documentFiles.map((file) => ({
    title: getUpdatedDocumentName(file),
    url: file.url,
    internal: true,
  }))
  const formData = {
    ...pick(values, itemFields),
    links: [...links.map((link) => ({ ...link, external: true })), ...documentLinks],
  }

  let submission
  switch (mode) {
    case ACTIONS.UPLOAD_PDF:
      const documentAsFile = documentFiles.length
        ? {
            title: getUpdatedDocumentName(documentFiles[0]),
            url: documentFiles[0].url,
            type: "pdf",
          }
        : {}

      submission = updateDocumentAsFile({
        opfDocument: {
          ...item,
          documentAsFile,
        },
      }).then(async () => await fetchDocument({ slug: getCurrentSlug(currentLanguage) }))
      break
    case ACTIONS.EDIT_RELATIONS:
      {
        const { relatedDocuments, relatedProcesses } = values
        const relatedPoliciesAndStandards = relatedDocuments
          ? uniq(
              relatedDocuments
                .map(getRelatedDocumentById)
                .flatMap(getChildPoliciesAndStandards)
                .map((item) => item.opfId)
                .filter((id) => id !== item.opfId)
            )
          : []
        submission = updateRelatedItems({
          opfDocument: { ...item, relatedProcesses, relatedDocuments: relatedPoliciesAndStandards },
        })
      }
      break
    case ACTIONS.EDIT_TOOLS:
      submission = updateLinks({ opfDocument: { ...item, ...formData } }).then(
        async () => await fetchDocument({ slug: getCurrentSlug(currentLanguage) })
      )
      break
    default:
      submission = Promise.resolve()
      break
  }
  return submission.then(closeModal).then(() => {
    const message = intl?.formatMessage({ id: "message.savedChanges" })
    notification.success({ message })
  })
}

DocumentForm.propTypes = {
  mode: PropTypes.string,
  formValues: PropTypes.object,
  getUserItemRights: PropTypes.func,
  mapToMediaLinks: PropTypes.func,
  closeModal: PropTypes.func,
  updateDocument: PropTypes.func,
  uploadDocumentFile: PropTypes.func,
  ...propTypes,
}
const form = "documentMeta"
const ConnectedDocumentForm = reduxForm({ form, onSubmit })(DocumentForm)
const mapStateToProps = bindSelectors({
  formValues: getFormValues(form),
  getUserItemRights: selectors.userSelectors.getUserItemRights,
  mapToMediaLinks: selectors.mapToMediaLinks,
  getChildPoliciesAndStandards: selectors.documentsSelectors.getAllRelatedChildPoliciesAndStandards,
  getRelatedDocumentById: selectors.documentsSelectors.getRelatedDocumentById,
  currentLanguage: selectors.localeSelectors.getCurrentLanguage,
})
const mapDispatchToProps = bindActions({
  closeModal: actions.uiActions.closeFormModal,
  updateDocumentAsFile: actions.documentsActions.updateDocumentAsFile,
  updateLinks: actions.documentsActions.updateLinks,
  updateRelatedItems: actions.documentsActions.updateRelatedItems,
  uploadDocumentFile: actions.documentsActions.uploadDocumentFile,
  fetchDocument: actions.documentsActions.fetchDocumentBySlug,
})
const IntlDocumentForm = injectIntl(ConnectedDocumentForm)
export default connect(mapStateToProps, mapDispatchToProps)(IntlDocumentForm)

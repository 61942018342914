import { Card, Table } from "antd"
import { isBoolean, pick, startCase, toString } from "lodash"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import React from "react"
import moment from "moment"

import { getTypeIntlKey } from "../../../services/itemTypes"
import ExpandableHtml from "src/components/ExpandableHtml"
import FormattedTitle from "src/components/FormattedTitle"

import "./style.less"

export const ReviewStep = (props) => {
  const { value, intl } = props
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "Value",
      dataIndex: "value",
      width: "70%",
    },
  ]
  let valuesToDisplay = pick(
    value,
    "type",
    "title",
    "text",
    "viewableByExternals",
    "draft",
    "editorialVersion",
    "documentOwner",
    "nextReviewDate"
  )
  if (value !== undefined && value.newOrder) {
    const newItem = value.newOrder.find((item) => item.new === true)
    if (newItem) {
      valuesToDisplay = { order: newItem.order, ...valuesToDisplay }
    }
  }
  const dataSource = Object.entries(valuesToDisplay).reduce((accumulated, keyValuePair) => {
    const [key, currentValue] = keyValuePair

    if (currentValue === undefined || currentValue === null) return accumulated

    let valueString
    switch (key) {
      case "type":
        valueString = (
          <FormattedTitle
            id={getTypeIntlKey(currentValue)}
            values={{ one: (chunk) => chunk, plural: () => "" }}
          />
        )
        break
      case "nextReviewDate":
        valueString = moment(currentValue).format(intl.formatMessage({ id: "form.dateFormat" }))
        break
      default:
        valueString = <ExpandableHtml text={toString(currentValue)} maxLine={4} />
        break
    }

    if (isBoolean(currentValue)) {
      valueString = <FormattedTitle id={"common." + (currentValue ? "yes" : "no")} />
    }

    let name
    switch (key) {
      case "text":
        name = startCase(intl.formatMessage({ id: `common.description` }))
        break
      default:
        name = startCase(intl.formatMessage({ id: `common.${key}` }))
        break
    }

    return [
      ...accumulated,
      {
        key,
        value: valueString,
        name: name,
      },
    ]
  }, [])

  return (
    <div className="fade-in">
      <Card title={<FormattedMessage id="form.step.review.title" />} bordered={false}>
        <FormattedMessage
          id="form.step.review.description"
          values={{
            strong: (chunk) => <strong>{chunk}</strong>,
          }}
        />
      </Card>
      <div className="form-step__content">
        <Table
          className="review-step-table"
          title={() => <strong>{valuesToDisplay.title}</strong>}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          showHeader={false}
          size="small"
        />
      </div>
    </div>
  )
}

ReviewStep.propTypes = {
  value: PropTypes.object,
}
export default ReviewStep

import { Form, Button, Modal } from "antd"
import { useEffect, useState } from "react"
import { getFormValues, reduxForm, change } from "redux-form"
import { Scrollbars } from "react-custom-scrollbars"
import { useSelector } from "react-redux"
import actions from "src/store/actions"
import useActions from "src/hooks/useActions"
import StepNavigators from "src/components/StepNavigators"
import selectors from "src/store/selectors"
import MinorMajorRadioGroup from "../components/MinorMajorRadioGroup"
import ChangeLogFields from "../components/ChangeLogFields"
import FormMediaWall from "../components/FormMediaWall"

import "./style.less"
import FormattedTitle from "src/components/FormattedTitle"
import { ReasonForChange } from "src/globals"

const form = "publishVersionForm"

type MinorMajorProps = {
  handleSubmit: (submitHandler: (formData: any) => void) => void
  onPublishDraft: (formData: any) => void
  item: any
}
type CustomUploadRequestParams = {
  onSuccess: (result: { media: any }, media: any) => any
  file: any
}
type FormValue = {
  image: {
    fileList: []
    file: {
      url: string
    }
  }
  changeLog?: string
  howTo?: string
  text?: string
  updateType: string
  reasonForChange?: ReasonForChange
  changeTo?: string
}

export function MinorMajor({ handleSubmit, item, onPublishDraft }: MinorMajorProps) {
  const [uploadFile, changeFormValue] = useActions([actions.itemActions.uploadFile, change])
  const formValues = useSelector(getFormValues(form)) as FormValue
  const fileList = useSelector(selectors.mapToMediaLinks)(formValues?.image?.fileList)
  const getItemBySlug = useSelector(selectors.itemSelectors.getItemBySlug)
  const [preview, setPreview] = useState({
    loading: false,
    visible: false,
    image: "",
    canSubmit: true,
  })
  const customUploadRequest = ({ onSuccess, file }: CustomUploadRequestParams) => {
    const opfItem = item.opfId ? item : getItemBySlug(item.parentSlug)
    setPreview((prev) => ({ ...prev, loading: true }))
    return uploadFile({ opfItem, file }).then((result: any) => {
      onSuccess(result, result.media)
      setPreview((prev) => ({ ...prev, loading: false }))
    })
  }
  const removeFile = (currentFile: { uid: string }) => {
    const fileList = formValues.image.fileList.filter(
      (file: { uid: string }) => file.uid !== currentFile.uid
    )
    changeFormValue(form, "image.fileList", fileList)
  }
  const handlePreview = (file: { thumbUrl: string }) =>
    setPreview((prev) => ({ ...prev, image: file.thumbUrl, visible: true }))
  const handleCancelPreview = () => setPreview((prev) => ({ ...prev, visible: false }))

  const publishDraftHandler = (data: any) => {
    const { changeLog, howTo, text, image, reasonForChange, changeTo } = formValues
    const formData = {
      notification: {
        changeLog,
        howTo,
        text,
        reasonForChange,
        changeTo,
        image: image?.fileList.length > 0 ? image.file.url : null,
      },
      ...data,
      isMajorUpdate: formValues.updateType === "major",
    }
    onPublishDraft(formData)
  }

  useEffect(() => {
    if (formValues?.updateType === "minor") {
      setPreview((prev) => ({ ...prev, canSubmit: (!!formValues?.changeLog && !!formValues?.changeTo) }))
      return
    }
    const canSubmit = !!(formValues?.changeLog && formValues?.reasonForChange && !!formValues?.changeTo)
    setPreview((prev) => ({ ...prev, canSubmit }))
  }, [formValues?.updateType, formValues?.changeLog, formValues?.reasonForChange, formValues?.changeTo])

  return (
    <>
      <Form className="ant-form publish-version-form">
        <Scrollbars autoHide hideTracksWhenNotNeeded>
          <div className="ant-form__spacing">
            <MinorMajorRadioGroup name="updateType" disabled={false} />
            <ChangeLogFields isMajorUpdate={formValues?.updateType === "major"}/>
            <FormMediaWall
              fileList={fileList}
              item={item}
              customUploadRequest={customUploadRequest}
              handlePreview={handlePreview}
              removeFile={removeFile}
            />
          </div>
        </Scrollbars>
      </Form>
      <StepNavigators className="row">
        <div className="col-xs end-xs">
          <Button
            className="test"
            htmlType="button"
            onClick={handleSubmit(publishDraftHandler) as any}
            type="primary"
            disabled={preview.loading || !preview.canSubmit}
          >
            <FormattedTitle id="common.confirm" />
          </Button>
        </div>
      </StepNavigators>
      <Modal
        className="media-wall-preview-modal"
        open={preview.visible}
        footer={null}
        onCancel={handleCancelPreview}
        centered
        style={{ position: "relative", maxHeight: "96vh", textAlign: "center" }}
      >
        <img alt="example" src={preview.image} />
      </Modal>
    </>
  )
}

const ConnectedMinorMajor = reduxForm<{}, MinorMajorProps>({ form })(MinorMajor)
export default ConnectedMinorMajor

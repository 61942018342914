import { Avatar, Card, Input, List } from "antd"
import { FormattedMessage } from "react-intl"
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc"
import { Field } from "redux-form"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"

import FormattedTitle from "src/components/FormattedTitle"
import { OpfItemShape } from "src/components/opf-prop-types"
import { bindSelectors } from "src/store/utils"
import { byOrder } from "src/components/helpers"
import { getTypeIntlKey } from "../../../services/itemTypes"
import { makeField } from "src/inputs/connected-input-fields"
import selectors from "src/store/selectors"
import ItemSortingList from "src/components/ItemSortingList"

const ListItem = List.Item
const setOrderByIndex = (item, index) => ({ ...item, order: index + 1 })
const metaStyles = {
  display: "flex",
  alignItems: "center",
}

const ItemCard = SortableElement((props) => {
  let title = props.global ? props.title + " (GLOBAL)" : props.title
  return (
    <ListItem style={{ cursor: "pointer" }} className={props.className}>
      <List.Item.Meta
        style={metaStyles}
        avatar={<Avatar>{props.order}</Avatar>}
        title={<div>{title}</div>}
        description={<FormattedTitle id={getTypeIntlKey(props.type)} />}
      />
    </ListItem>
  )
})

const SortableList = SortableContainer(({ items }) => (
  <ItemSortingList size="small">
    {items &&
      items
        .sort(byOrder)
        .map((item, index) => <ItemCard key={item.order} index={index} {...item} />)}
  </ItemSortingList>
))

const SortableField = makeField((props) => {
  const { onChange, defaultValue, newItem, ...rest } = props
  const items = props.value || defaultValue
  const updateNew = (value) => (value && value.new && newItem ? newItem : value)
  const moveItem = ({ oldIndex, newIndex }) => {
    const updatedList = items.map(updateNew)
    const newOrder = arrayMove(updatedList, oldIndex, newIndex).map(setOrderByIndex)
    onChange(newOrder)
  }
  return (
    <div>
      <SortableList
        items={items.map(updateNew).map(setOrderByIndex)}
        helperClass="is-moving"
        onSortEnd={moveItem}
      />
      <Input {...rest} style={{ display: "none" }} readOnly />
    </div>
  )
})

export const SetOrderStep = (props) => {
  const { parentItem, newItem, getItemBySlug } = props
  if (!parentItem) return null
  const hasDraft =
    parentItem.draftVersion &&
    parentItem.draftVersion !== null &&
    parentItem.version < parentItem.draftVersion.version
  let items = isEmpty(parentItem.steps)
    ? parentItem.opfChildren
      ? parentItem.opfChildren.map((child) => getItemBySlug(child.slug)).sort(byOrder)
      : []
    : hasDraft
    ? parentItem.draftVersion.steps
    : parentItem.steps
  if (newItem) items = [...items, newItem]
  return (
    <div className="fade-in">
      <Card title={<FormattedMessage id="form.step.setOrder.title" />} bordered={false}>
        <FormattedMessage id="form.step.setOrder.description" />
      </Card>
      <Field name="newOrder" component={SortableField} defaultValue={items} newItem={newItem} />
    </div>
  )
}

SetOrderStep.propTypes = {
  newItem: OpfItemShape,
  parentItem: OpfItemShape,
  getItemBySlug: PropTypes.func,
}
const mapStateToProps = bindSelectors({
  getItemBySlug: selectors.itemSelectors.getItemBySlug,
})
export default connect(mapStateToProps)(SetOrderStep)

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useIntl } from "react-intl";
import FormattedTitle from "src/components/FormattedTitle";

import "./style.less"

export default function Thumbnail({ file, handlePreview, removeFile }) {
  const intl = useIntl()

  return (
    <div className="thumbnail">
      <Tooltip
        title={
          <FormattedTitle
            id="common.remove"
            values={{
              target: () => intl.formatMessage({ id: "common.image" }, { one: chunk => chunk, plural: () => "" }),
            }}
          />
        }
      >
        <Button
          className="thumbnail__delete"
          size="small"
          icon={<DeleteOutlined />}
          shape="circle"
          onClick={() => removeFile(file)}
        />
      </Tooltip>
      <div className="thumbnail__image-wrap" onClick={() => handlePreview(file)}>
        <div
          className="thumbnail__image"
          style={{ backgroundImage: `url(${file.thumbUrl})` }}
        />
      </div>
    </div>
  )
}
import { Alert } from "antd"
import { Field } from "redux-form"
import PropTypes from "prop-types"
import React from "react"

import { IconPickerField, InputField, RichTextField } from "src/inputs/connected-input-fields"
import FormattedTitle from "src/components/FormattedTitle"
import { OPF_ITEM_TYPES } from "../../../globals"
import { required } from "../../validators"

export const TitleAndDescriptionStep = ({ cardTitle, cardDescription, itemType }) => (
  <div className="fade-in">
    {cardTitle || cardDescription ? (
      <Alert message={cardTitle} description={cardDescription} type="info" showIcon />
    ) : null}
    <div className="row">
      {itemType === OPF_ITEM_TYPES.PROCEDURE && (
        <div>
          <Field
            name="icon"
            component={IconPickerField}
            label={<FormattedTitle id="common.icon" />}
          />
        </div>
      )}
      <div style={{ flex: 1 }}>
        <Field
          name="title"
          component={InputField}
          validate={[required]}
          label={<FormattedTitle id="common.title" />}
        />
      </div>
    </div>
    <div className="row">
      <div style={{ flex: 1 }}>
        <Field
          name="text"
          component={RichTextField}
          validate={[required]}
          className="area"
          label={<FormattedTitle id="common.description" />}
        />
      </div>
    </div>
  </div>
)

TitleAndDescriptionStep.propTypes = {
  cardTitle: PropTypes.any,
  cardDescription: PropTypes.any,
}

export default TitleAndDescriptionStep

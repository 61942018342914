import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  GlobalOutlined,
  SolutionOutlined,
} from "@ant-design/icons"

import { Card } from "antd"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import FormattedTitle from "src/components/FormattedTitle"
import { SwitchField } from "src/inputs/connected-input-fields"
import { UserProfileShape } from "src/components/opf-prop-types"
import { bindSelectors } from "src/store/utils"
import selectors from "src/store/selectors"

import "./style.less"

export const MetadataStep = (props) => {
  const { user, children, getConfiguratorTitle } = props
  const tagAValue = user.currentTagA ? getConfiguratorTitle({ tagA: user.currentTagA }) : null
  const tagBValue = user.currentTagB ? getConfiguratorTitle({ tagB: user.currentTagB }) : null
  const tagCValue = user.currentTagC ? getConfiguratorTitle({ tagC: user.currentTagC }) : null

  return (
    <div className="fade-in">
      <Card title={<FormattedMessage id="form.step.setMetadata.title" />} bordered={false}>
        <FormattedMessage
          id="form.step.setMetadata.description"
          values={{
            strong: (chunk) => <strong>{chunk}</strong>,
          }}
        />
      </Card>
      {(user.currentTagA || user.currentTagB || user.currentTagC) && (
        <div className="row form-wizard__section">
          <span className="ant-form-item-label">
            <FormattedMessage id="common.relevantFor" />:
          </span>
          <div className="pills">
            {user.currentTagA && (
              <div className="pill">
                <GlobalOutlined />
                {tagAValue}
              </div>
            )}
            {tagBValue && (
              <div className="pill">
                <SolutionOutlined />
                {tagBValue}
              </div>
            )}
            {tagCValue && (
              <div className="pill">
                <BankOutlined />
                {tagCValue}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="row form-wizard__section">
        <div className="ant-form-item-label ant-form-item-label--fluid">
          <span>
            <FormattedTitle id="common.viewableByExternals" />
          </span>
        </div>
        <Field
          name="viewableByExternals"
          className="form-wizard__switch"
          component={SwitchField}
          disableValidation
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </div>
      {children}
    </div>
  )
}

MetadataStep.propTypes = {
  user: UserProfileShape,
  getConfiguratorTitle: PropTypes.func,
}
const mapStateToProps = bindSelectors({
  user: selectors.userSelectors.getUser,
  getConfiguratorTitle: selectors.configuratorSelectors.getConfiguratorTitle,
})
export default connect(mapStateToProps)(MetadataStep)

import { CheckOutlined, CloseOutlined, DeleteOutlined, DragOutlined } from "@ant-design/icons"
import { Form, Button, Divider, Empty, Modal, Tooltip, TreeSelect, notification } from "antd"
import { Field, FieldArray, destroy, getFormValues, reduxForm } from "redux-form"
import { FormattedMessage, injectIntl } from "react-intl"
import { Component, Fragment } from "react"
import { has, isEmpty, pick, sortBy, uniqueId } from "lodash"
import PropTypes from "prop-types"
import { Twemoji } from "react-emoji-render"
import { connect } from "react-redux"
import flag from "country-code-emoji"
import titleize from "titleize"

import {
  DateField,
  FileDragAndDropField,
  IconPickerField,
  InputField,
  RichTextField,
  SwitchField,
  TreeSelectField,
} from "src/inputs/connected-input-fields"
import { ACTIONS, OPF_ITEM_TYPES } from "../../globals"
import { bindActions, bindSelectors } from "src/store/utils"
import {
  getNodeIdByItemSlug,
  linkToFile,
  normalizeLanguageCode,
  sanitizeHTML,
} from "src/components/helpers"
import ClassificationSelect from "src/inputs/ClassificationSelect"
import FormattedTitle from "src/components/FormattedTitle"
import ProcedureStep from "src/components/opf-items/ProcedureStep"
import actions from "src/store/actions"
import { mustBeEmail, mustBeDecimal, required } from "src/forms/validators"
import selectors from "src/store/selectors"
import ItemMeta from "src/components/ItemMeta"
import Placeholder from "src/inputs/Placeholder"

// FIXME: This component need styling from Procedure to look right.
import "src/components/opf-items/Procedure"

import "./style.less"

const itemFields = [
  "accessRoles",
  "type",
  "icon",
  "title",
  "text",
  "mediaLibrary",
  "draft",
  "viewableByExternals",
  "nextReviewDate",
  "contactEmail",
  "reviewDate",
  "nextReviewDateIsApplicable",
  "classification",
  "editorialVersion",
  "documentOwner",
]

export class ProcedureEditForm extends Component {
  state = {
    loading: false,
    savingDraft: false,
    publishing: false,
    previewVisible: false,
    previewImage: "",
    isReviewDateDisabled: false,
  }

  handleInitialize() {
    const { item, initialize, procedureEditMode } = this.props

    if (!item) return
    const {
      title,
      text,
      icon,
      mediaLibrary,
      nextReviewDate,
      nextReviewDateIsNotApplicable,
      viewableByExternals,
      steps,
      accessRoles,
      slug,
      classification,
      editorialVersion,
      documentOwner
    } =
      item.draftVersion !== null &&
      // procedureEditMode !== ACTIONS.CREATE_VARIANT &&
      procedureEditMode !== ACTIONS.TRANSLATE
        ? item.draftVersion
        : item
    const mediaFileList = mediaLibrary ? mediaLibrary.map(linkToFile).filter(Boolean) : []
    initialize({
      title: title,
      text: text || "",
      icon: icon,
      draft: null,
      mediaLibrary: mediaLibrary || [],
      procedureMediaFiles: mediaFileList ? { fileList: mediaFileList } : [],
      nextReviewDate: nextReviewDate,
      nextReviewDateIsApplicable: nextReviewDateIsNotApplicable
        ? !nextReviewDateIsNotApplicable
        : true,
      viewableByExternals: viewableByExternals,
      accessRoles: accessRoles || [],
      classification: classification && classification !== 0 ? classification : 0,
      steps: steps ? steps.map((step) => ({ ...step, parentSlug: slug, archiveStep: false })) : [],
      editorialVersion: editorialVersion ?? "1.0",
      documentOwner: documentOwner ?? " ",
    })
  }

  renderSteps = ({ fields, props }) => {
    const { item, formValues, translating = false, showActions = true } = this.props
    const emptyDisplay =
      formValues && isEmpty(formValues.steps) ? (
        <Placeholder className="placeholder--medium">
          <Empty description={<FormattedMessage id="message.noSteps" />} />
        </Placeholder>
      ) : null
    const newObj = {
      stepId: uniqueId("temp_"),
      title: "",
      text: "",
      links: [],
      mediaLibrary: [],
      type: OPF_ITEM_TYPES.PROCEDURE_ELEMENT,
      parentSlug: item.slug || "",
      parentId: item.id || "",
      archiveStep: false,
      new: true,
    }

    return (
      <>
        {emptyDisplay}
        {fields &&
          fields.map((step, index) => (
            <ProcedureStep
              key={fields.get(index).stepId}
              item={fields.get(index)}
              previewFile={(file) => this.handlePreview(file)}
              parentItem={item}
              index={index}
              language={item.language}
              majorVersion={item.majorVersion}
              translating={translating}
              showActions={showActions}
              editMode
            />
          ))}
        {showActions && !translating && (
          <Button className="step__add-btn" onClick={() => fields.push(newObj)}>
            <FormattedTitle id="button.addStep" />
          </Button>
        )}
      </>
    )
  }

  handlePreview = (file) => {
    this.setState({
      previewImage: file.thumbUrl ? file.thumbUrl : file.url,
      previewVisible: true,
    })
  }

  handleCancelPreview = () => this.setState({ previewVisible: false })

  customUploadRequest = ({ onSuccess, onError, file }) => {
    const { uploadFile, getItemBySlug } = this.props
    const item = this.props.item.opfId ? this.props.item : getItemBySlug(this.props.item.parentSlug)
    return uploadFile({ opfItem: item, file }).then((result) => onSuccess(result, result.media))
  }

  removeFile = (currentFile) => {
    const { change, formValues } = this.props
    const fileList = formValues.procedureMediaFiles.fileList.filter(
      (file) => file.uid !== currentFile.uid
    )
    change("procedureMediaFiles.fileList", fileList)
  }

  saveAsDraft = () => {
    this.setState({ savingDraft: true })
    const { item, formValues, update, intl } = this.props
    const mediaFiles = has(formValues, "procedureMediaFiles.fileList")
      ? formValues.procedureMediaFiles.fileList
      : []
    const mediaLinks = mediaFiles.map((file) => ({
      title: file.name,
      url: file.url,
    }))
    const formData = {
      ...pick(formValues, itemFields),
      draft: true,
      mediaLibrary: [...mediaLinks],
      steps: formValues.steps
        .filter((item) => !item.archiveStep)
        .map((item) => ({ ...item, stepId: item.stepId.startsWith("temp_") ? null : item.stepId })),
    }
    return update({ opfItem: { ...item, ...formData } }).then(() => {
      const message = intl.formatMessage({ id: "message.savedChanges" })
      window.requestAnimationFrame(() => !this.unmounted && this.setState({ savingDraft: false }))
      this.leaveEditMode()
      notification.success({ message })
    })
  }

  renderNotificationModal = () => {
    const { openFormModal, item } = this.props
    openFormModal({ opfItem: item, mode: ACTIONS.SET_NOTIFICATION })
  }

  // createVariantDocument = () => {
  //   const { item, formValues, createVariant, intl } = this.props
  //   const mediaFiles = has(formValues, "procedureMediaFiles.fileList")
  //     ? formValues.procedureMediaFiles.fileList
  //     : []
  //   const mediaLinks = mediaFiles.map((file) => ({
  //     title: file.name,
  //     url: file.url,
  //   }))
  //   const formData = {
  //     ...pick(formValues, itemFields),
  //     mediaLibrary: [...mediaLinks],
  //     steps: formValues.steps
  //       .filter((item) => !item.archiveStep)
  //       .map((item) => ({ ...item, stepId: item.stepId.startsWith("temp_") ? null : item.stepId })),
  //   }
  //   return createVariant({ opfItem: { ...item, ...formData } }).then(() => {
  //     const message = intl.formatMessage({ id: "message.savedChanges" })
  //     this.leaveEditMode()
  //     notification.success({ message })
  //   })
  // }

  handleSetStepOrderModal = () => {
    const { openFormModal, item, formValues } = this.props
    const newOrder = formValues.newOrder ? formValues.newOrder : null
    const edittedItem = newOrder
      ? { ...item, ...formValues, steps: newOrder }
      : { ...item, ...formValues }
    const currentItem = edittedItem.draftVersion !== null ? edittedItem.draftVersion : edittedItem
    openFormModal({ opfItem: currentItem, mode: ACTIONS.UPDATE_ORDER })
  }

  getServiceLineTreeNodes = (data) => {
    if (isEmpty(data)) return null
    const sortedData = sortBy(data, "name")
    return sortedData.map((item) => {
      const children = item && item.roles ? item.roles : null
      return {
        title: item.name,
        key: item.code,
        value: item.code,
        children: children ? this.getServiceLineTreeNodes(children) : null,
      }
    })
  }

  componentDidMount() {
    const { fetchServiceLines, item } = this.props
    fetchServiceLines({ language: item.language, fallbackLanguage: null })
    this.handleInitialize()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      const { procedureEditMode, setProcedureEditMode } = this.props
      if (procedureEditMode) setProcedureEditMode(false)
    }
    if (prevProps.item.id !== this.props.item.id) {
      this.props.destroyForm(form)
      this.handleInitialize()
    }
  }

  render() {
    const {
      item,
      procedureEditMode,
      addProcedureMode = false,
      currentLanguage,
      defaultLanguage,
      mapToMediaLinks,
      splitView,
      parentItemTitle,
      formValues,
      serviceLines,
      intl,
      loading,
      translating = false,
      getFormItemViewTitle,
      getFormItemViewDescription,
    } = this.props

    if (!item) return null
    const { slug } = item
    const editing = procedureEditMode === ACTIONS.EDIT
    const editingTranslatedItem = editing && item.translated
    const hasDraft = item.draftVersion !== null && item.version < item.draftVersion.version
    const nextReviewDateValue = hasDraft ? item.draftVersion.nextReviewDate : item.nextReviewDate
    const fileList =
      formValues &&
      formValues.procedureMediaFiles &&
      !isEmpty(formValues.procedureMediaFiles.fileList)
        ? mapToMediaLinks(formValues.procedureMediaFiles.fileList)
        : []
    const treeData = this.getServiceLineTreeNodes(serviceLines)
    if (!treeData) return null //Return early to prevent unnescessary renders

    const viewTitle = getFormItemViewTitle({ intl, item, addProcedureMode, parentItemTitle })
    const viewDescription = getFormItemViewDescription({ intl, item, addProcedureMode })
    const isTranslating =
      editing && !editingTranslatedItem && currentLanguage !== defaultLanguage
        ? item.language !== defaultLanguage && translating
        : translating

    return (
      <Fragment
        key={"form_" + getNodeIdByItemSlug(slug) + (translating ? "translating" : "editing")}
      >
        <Form>
          <div
            className="page procedure procedure--edit fade-in"
            id={getNodeIdByItemSlug(slug)}
            style={this.props.style}
          >
            {(procedureEditMode === ACTIONS.EDIT && item.global && !splitView) ||
            addProcedureMode ? (
              <div className="edit__header edit__header--singleColumn">
                <h1>
                  <FormattedMessage {...viewTitle} />
                </h1>
                <p
                  className="edit__description"
                  dangerouslySetInnerHTML={sanitizeHTML(viewDescription)}
                />
                <div className="edit__flag-indicator edit__flag-indicator--editMode">
                  <Twemoji
                    svg
                    text={flag(
                      normalizeLanguageCode(
                        item.language !== currentLanguage || (addProcedureMode && item.global)
                          ? defaultLanguage
                          : currentLanguage
                      )
                    )}
                  />
                </div>
              </div>
            ) : null}
            <div className="row">
              <div>
                <Field
                  name="icon"
                  component={IconPickerField}
                  disabled={isTranslating}
                  label={titleize(intl.formatMessage({ id: "common.icon" }))}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Field
                  name="title"
                  className="item-title item-title--edit"
                  component={InputField}
                  label={titleize(intl.formatMessage({ id: "common.title" }))}
                  placeholder={titleize(`${intl.formatMessage({ id: "common.title" })}...`)}
                  validate={[required]}
                />
              </div>
            </div>
            <Field
              name="text"
              component={RichTextField}
              itemKey={"procedure_" + item.id + "_" + item.language + "_" + item.majorVersion}
              className="abstract-area abstract-area--edit"
              label={titleize(intl.formatMessage({ id: "common.description" }))}
              placeholder={titleize(`${intl.formatMessage({ id: "common.description" })}...`)}
              validate={[required]}
            />
            <>
              {isEmpty(fileList) && translating ? null : (
                <label className="text-label">
                  <FormattedTitle
                    id="common.image"
                    values={{ one: () => "", plural: (chunk) => chunk }}
                  />{" "}
                  :
                </label>
              )}
              <div className="media-wall">
                {isEmpty(fileList)
                  ? null
                  : fileList.map((file, index) => (
                      <div className="media" key={index}>
                        <div className="media__image-wrap">
                          <Tooltip
                            title={
                              <FormattedTitle
                                id="common.remove"
                                values={{
                                  target: () =>
                                    intl.formatMessage(
                                      { id: "common.image" },
                                      { one: (chunk) => chunk, plural: () => "" }
                                    ),
                                }}
                              />
                            }
                          >
                            <Button
                              className="media__delete"
                              size="small"
                              icon={<DeleteOutlined />}
                              shape="circle"
                              onClick={() => this.removeFile(file)}
                            />
                          </Tooltip>
                          <div
                            className="media__image"
                            onClick={() => this.handlePreview(file)}
                            style={{
                              backgroundImage: `url(${file.thumbUrl ? file.thumbUrl : file.url})`,
                            }}
                          />
                        </div>
                      </div>
                    ))}
                <Tooltip tile="Recommended aspect ratio 16:9" mouseEnterDelay={0.5}>
                  <div>
                    <Field
                      name="procedureMediaFiles"
                      className="media-wall__upload-field"
                      component={FileDragAndDropField}
                      customUploadRequest={this.customUploadRequest}
                      acceptedFileType="image/png,image/jpg,image/jpeg,image/gif"
                      multiple={false}
                      item={item}
                      defaultValue={isEmpty(fileList) ? {} : { fileList, file: {} }}
                    />
                  </div>
                </Tooltip>
              </div>
            </>
            <ItemMeta>
              <div className="item-meta__item col-xs-12">
                <div className="item-meta__value tree-select-dropdown">
                  <Field
                    name="accessRoles"
                    component={TreeSelectField}
                    value={this.state.selectedServiceLines}
                    defaultValue={item ? item.accessRoles : null}
                    onBlur={(event) => event.preventDefault()}
                    treeCheckable
                    showSearch
                    disabled={isTranslating}
                    loading={loading}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    placeholder={intl.formatMessage({
                      id: "common.selectServicelinePlaceholder",
                    })}
                    label={intl.formatMessage({ id: "common.relevantFor" })}
                    dropdownStyle={{ maxHeight: 310, overflowY: "scroll" }}
                    treeData={treeData}
                  />
                </div>
              </div>
              <div className="item-meta__item col-xs-12" style={{ maxWidth: 250 }}>
                <div className="item-meta__value">
                  <label className="text-label">
                    {intl.formatMessage({ id: "common.reviewDate" })}:{" "}
                  </label>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <div>
                      <Field
                        name="nextReviewDateIsApplicable"
                        component={SwitchField}
                        disabled={isTranslating}
                        disableValidation
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </div>
                    <div style={{ marginTop: 10 }}>
                      {formValues && !formValues.nextReviewDateIsApplicable ? (
                        <Tooltip title={intl.formatMessage({ id: "common.notApplicable" })}>
                          <div>
                            <Field
                              name="nextReviewDateDisabled"
                              key="nextReviewDateDisabled"
                              component={DateField}
                              disabled
                              mode="day"
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Field
                          name="nextReviewDate"
                          component={DateField}
                          disabled={isTranslating}
                          defaultValue={item ? nextReviewDateValue : null}
                          mode="day"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-meta__item col-xs-12" style={{ maxWidth: 300 }}>
                <div className="item-meta__value">
                  <ClassificationSelect disabled={isTranslating} />
                </div>
              </div>
              <div className="item-meta__item col-xs-12" style={{ maxWidth: 150 }}>
                <label className="text-label">
                  {intl.formatMessage({ id: "common.viewableByExternals" })} :
                </label>
                <div className="item-meta__value">
                  <Field
                    name="viewableByExternals"
                    className="switch"
                    component={SwitchField}
                    disableValidation
                    disabled={isTranslating}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </div>
              </div>
              <div className="item-meta__item col-xs-12" style={{ maxWidth: 150 }}>
                <label className="text-label">
                  {intl.formatMessage({ id: "common.editorialVersion" })} :
                </label>
                <div style={{ marginTop: 14 }}>
                  <Field
                    name="editorialVersion"
                    component={InputField}
                    validate={[mustBeDecimal]}
                    disabled={isTranslating}
                  />
                </div>
              </div>
              <div className="item-meta__item col-xs-12" style={{ maxWidth: 300 }}>
                <label className="text-label">
                  {intl.formatMessage({ id: "common.documentOwnerEmailAddress" })} :
                </label>
                <div style={{ marginTop: 14 }}>
                  <Field
                    name="documentOwner"
                    component={InputField}
                    disabled={isTranslating}
                  />
                </div>
              </div>              
            </ItemMeta>
            <div className="col-xs-12 steps">
              <div className="row middle-xs">
                <h2 style={{ paddingRight: 15, lineHeight: "40px" }}>
                  <FormattedTitle
                    id="common.step"
                    values={{ one: () => "", plural: (chunk) => chunk }}
                  />
                </h2>
                {formValues && !isEmpty(formValues.steps) && !translating ? (
                  <Button onClick={this.handleSetStepOrderModal}>
                    <DragOutlined />
                    <span>
                      <FormattedMessage id="button.setOrder" />
                    </span>
                  </Button>
                ) : null}
                <Divider style={{ margin: "15px 0 0 0" }} />
              </div>
              <FieldArray
                name="steps"
                component={this.renderSteps}
                props={{ item: item }}
                withRef
              />
            </div>
          </div>
        </Form>
        <Modal
          className="media-wall-preview-modal"
          open={this.state.previewVisible}
          footer={null}
          onCancel={this.handleCancelPreview}
          centered
          style={{ position: "relative", maxHeight: "96vh", textAlign: "center" }}
        >
          <img alt="example" src={this.state.previewImage} />
        </Modal>
      </Fragment>
    )
  }
}

ProcedureEditForm.propTypes = {
  getUserItemRights: PropTypes.func,
  refinerEnabled: PropTypes.bool,
  openFormModal: PropTypes.func,
  uploadFile: PropTypes.func,
  destroyForm: PropTypes.func,
  update: PropTypes.func,
  // createVariant: PropTypes.func,
  mapToMediaLinks: PropTypes.func,
  getItemBySlug: PropTypes.func,
  procedureEditMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
const form = "procedureForm"
const ProcedureFormComponent = reduxForm({ form })(ProcedureEditForm)
const mapStateToProps = bindSelectors({
  currentLanguage: selectors.localeSelectors.getCurrentLanguage,
  defaultLanguage: selectors.localeSelectors.getDefaultLanguage,
  getUserItemRights: selectors.userSelectors.getUserItemRights,
  refinerEnabled: selectors.refinerSelectors.getRefinerEnabled,
  formValues: getFormValues(form),
  mapToMediaLinks: selectors.mapToMediaLinks,
  procedureEditMode: selectors.itemSelectors.getProcedureEditModeState,
  currentRole: selectors.userSelectors.getCurrentRole,
  serviceLines: selectors.itemSelectors.getServiceLines,
  getLanguageTitle: selectors.localeSelectors.getLanguageTitle,
  getItemBySlug: selectors.itemSelectors.getItemBySlug,
  getFormItemViewTitle: selectors.itemSelectors.getFormItemViewTitle,
  getFormItemViewDescription: selectors.itemSelectors.getFormItemViewDescription,
})
const mapDispatchToProps = bindActions({
  setProcedureEditMode: actions.itemActions.setProcedureEditMode,
  openFormModal: actions.uiActions.openFormModal,
  uploadFile: actions.itemActions.uploadFile,
  update: actions.itemActions.updateItem,
  // createVariant: actions.itemActions.createItemVariant,
  fetchServiceLines: actions.itemActions.fetchServiceLines,
  destroyForm: destroy,
})
const IntlProcedureForm = injectIntl(ProcedureFormComponent)
export default connect(mapStateToProps, mapDispatchToProps)(IntlProcedureForm)

// where to find: https://localhost:44309/processes
// Role: Global Process Owner
// navigate to a document in Procedures / Work Instructions
// click: ... (menu) -> Edit

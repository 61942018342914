import { CloseOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons"
import { Button, Card, Tooltip } from "antd"
import { Field, FieldArray } from "redux-form"
import { FormattedMessage, useIntl } from "react-intl"
import React from "react"

import { required } from "../../validators"
import { InputField } from "src/inputs/connected-input-fields"

const VideosStep = (props) => {
  return (
    <div className="fade-in">
      <Card title={<FormattedMessage id="form.step.addVideoLinks.title" />} bordered={false}>
        <FormattedMessage
          id="form.step.addVideoLinks.description"
          values={{
            a: (chunk) => (
              <a href="https://tv.issworld.com" target="_blank" rel="noreferrer">
                {chunk}
              </a>
            ),
            br: () => <br />,
          }}
        />
      </Card>
      <FieldArray name="videos" component={VideoLinksContainer} />
    </div>
  )
}

const VideoLinksContainer = ({ fields }) => {
  return (
    <div>
      <ul className="link-fields-container">
        {fields.map((field, index) => (
          <VideoFields key={index} fields={fields} index={index} field={field} />
        ))}
      </ul>
      <div className="row center-xs">
        <Button onClick={() => fields.push({})}>
          <PlusOutlined /> <FormattedMessage id="form.step.addVideos.button.addField" />
        </Button>
      </div>
    </div>
  )
}

const VideoFields = ({ field, index, fields }) => {
  const intl = useIntl()
  const video = fields.get(index)
  const isTopField = index === 0

  return (
    <li key={`${video.title}_${video.url}_${index}`} className="fade-in-down">
      <div className="link-details">
        <div style={{ position: "absolute", top: 4, right: 0, zIndex: 2 }}>
          <span style={{ marginRight: "30px" }}>
            <Tooltip placement="topRight" title={intl.formatMessage({ id: "common.moveUp" })}>
              <Button
                type="dashed"
                shape="circle"
                icon={<UpOutlined />}
                onClick={() => {
                  fields.swap(index, index - 1)
                }}
                disabled={isTopField}
              />
            </Tooltip>
          </span>

          <Tooltip
            placement="topRight"
            title={intl.formatMessage({ id: "common.remove" }, { target: () => "video" })}
          >
            <Button
              shape="circle"
              type="dashed"
              icon={<CloseOutlined />}
              onClick={() => {
                fields.remove(index)
              }}
            />
          </Tooltip>
        </div>

        <Field
          name={`${field}.title`}
          validate={[required]}
          component={InputField}
          label={intl.formatMessage({ id: "input.addVideo.titleField.label" })}
          placeholder={intl.formatMessage({
            id: "input.addVideo.titleField.label",
          })}
        />
        <Field
          name={`${field}.thumbnail`}
          validate={[required]}
          component={InputField}
          label={intl.formatMessage({
            id: "input.addVideo.thumbnailField.label",
          })}
          placeholder={intl.formatMessage({
            id: "input.addVideo.thumbnailField.label",
          })}
        />
        <Field
          name={`${field}.url`}
          validate={[required]}
          component={InputField}
          label={intl.formatMessage({ id: "input.addVideo.videoField.label" })}
          placeholder={intl.formatMessage({
            id: "input.addVideo.videoField.label",
          })}
        />
      </div>
    </li>
  )
}

export default VideosStep

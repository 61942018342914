import { CloseOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons"
import { Button, Card, Tooltip } from "antd"
import { Field, FieldArray } from "redux-form"
import { FormattedMessage, useIntl } from "react-intl"
import React from "react"

import { InputField, LinkInput } from "src/inputs/connected-input-fields"
import { mustBeValidCustomLink, required } from "../../validators"
import FormattedTitle from "src/components/FormattedTitle"

const LinksStep = (props) => {
  return (
    <div className="fade-in">
      <Card
        title={
          <FormattedTitle
            id={"form.step.addLinks.title"}
            values={{ one: () => "", plural: (chunk) => chunk }}
          />
        }
        bordered={false}
      >
        <FormattedMessage id="form.step.addLinks.description" />
      </Card>
      <FieldArray name="links" component={LinksContainer} />
    </div>
  )
}

const LinksContainer = ({ fields }) => {
  return (
    <div>
      <ul className="link-fields-container">
        {fields.map((field, index) => (
          <LinkFields key={index} field={field} index={index} fields={fields} />
        ))}
      </ul>
      <div className="row center-xs">
        <Button onClick={() => fields.push({ url: "http://", title: "" })}>
          <PlusOutlined /> <FormattedMessage id="form.step.addLinks.button.addField" />
        </Button>
      </div>
    </div>
  )
}

const LinkFields = ({ field, index, fields }) => {
  const intl = useIntl()
  const link = fields.get(index)
  const isTopField = index === 0

  return (
    <li key={`${link.url}_${link.title}_${index}`} className="fade-in-down">
      <div className="link-details">
        <div style={{ position: "absolute", top: 4, right: 0, zIndex: 2 }}>
          <span style={{ marginRight: "30px" }}>
            <Tooltip placement="topRight" title={intl.formatMessage({ id: "common.moveUp" })}>
              <Button
                type="dashed"
                shape="circle"
                icon={<UpOutlined />}
                onClick={() => {
                  fields.swap(index, index - 1)
                }}
                disabled={isTopField}
              />
            </Tooltip>
          </span>

          <Tooltip
            placement="topRight"
            title={intl.formatMessage({ id: "common.remove" }, { target: () => "link" })}
          >
            <Button
              shape="circle"
              type="dashed"
              icon={<CloseOutlined />}
              onClick={() => {
                fields.remove(index)
              }}
            />
          </Tooltip>
        </div>

        <Field
          name={`${field}.title`}
          component={InputField}
          validate={[required]}
          label={intl.formatMessage({ id: "input.addLink.titleField.label" })}
          placeholder={intl.formatMessage({
            id: "input.addLink.descriptionField.placeholder",
          })}
        />

        <Field
          name={`${field}.url`}
          validate={[required, mustBeValidCustomLink]}
          component={LinkInput}
        />
      </div>
    </li>
  )
}

export default LinksStep

import { Field } from "redux-form"
import { SwapOutlined } from "@ant-design/icons"
import React from "react"
import { Twemoji } from "react-emoji-render"
import flag from "country-code-emoji"
import { useSelector } from "react-redux"

import { InputField, RichTextField } from "src/inputs/connected-input-fields"
import FormattedTitle from "src/components/FormattedTitle"
import { OPF_ITEM_TYPES } from "../../../globals"
import { TabletBreakpoint } from "src/components/breakpoints"
import { required } from "../../validators"
import { sanitizeHTML } from "src/components/helpers"
import selectors from "src/store/selectors"

const validation = { validate: [required] }

export default function TranslateStep({ item }) {
  const currentLanguageName = useSelector(selectors.localeSelectors.getCurrentLanguageTitle)
  return (
    <div className="translate-form row">
      <div className="translate-form__header row between-xs middle-xs">
        <div className="translate-form__header__language">
          <div className="translate-form__header__flag">
            <Twemoji svg text={flag("gb")} />
          </div>
          <span>English</span>
        </div>
        <div className="translate-form__header__icon">
          <SwapOutlined />
        </div>
        <div className="translate-form__header__language">
          <span>{currentLanguageName}</span>
        </div>
      </div>
      <TabletBreakpoint>
        <div className="translate-form__global-content col-xs-12 col-sm-6">
          <div className="ant-form-item-label">
            {item.type === OPF_ITEM_TYPES.PROEDURE_ELEMENT ? (
              <label>
                Step <FormattedTitle id="common.title" />
              </label>
            ) : (
              <label>
                <FormattedTitle id="common.title" />
              </label>
            )}
          </div>
          <p className="translate-form__p">{item.title}</p>
          <div className="ant-form-item-label">
            {item.type === OPF_ITEM_TYPES.PROCEDURE_ELEMENT ? (
              <label>
                <FormattedTitle
                  id="common.task"
                  values={{ one: () => "", plural: (chunk) => chunk }}
                />
              </label>
            ) : (
              <label>
                <FormattedTitle id="common.description" />
              </label>
            )}
          </div>
          <p className="translate-form__p" dangerouslySetInnerHTML={sanitizeHTML(item.text)} />
        </div>
      </TabletBreakpoint>
      <div className="col-xs-12 col-sm-6 translate-form__form">
        <Field
          name="title"
          component={InputField}
          {...validation}
          defaultValue={null}
          label={<FormattedTitle id="common.title" />}
        />
        <Field
          name="text"
          component={RichTextField}
          className="area"
          {...validation}
          defaultValue={null}
          label={
            item.type === OPF_ITEM_TYPES.PROCEDURE_ELEMENT ? (
              <FormattedTitle
                id="common.task"
                values={{ one: () => "", plural: (chunk) => chunk }}
              />
            ) : (
              <FormattedTitle id="common.description" />
            )
          }
        />
      </div>
    </div>
  )
}

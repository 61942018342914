import { DeleteOutlined, PictureOutlined } from "@ant-design/icons"
import { Form, Button, Modal, Radio, Tooltip } from "antd"
import { Field, change, destroy, getFormValues, propTypes, reduxForm } from "redux-form"
import { FormattedMessage, injectIntl } from "react-intl"
import React, { Component } from "react"
import { has, isFunction, pick } from "lodash"
import PropTypes from "prop-types"
import { Scrollbars } from "react-custom-scrollbars"
import { connect } from "react-redux"

import { ACTIONS, ROLES } from "../../globals"
import {
  FileDragAndDropField,
  InputField,
  RadioGroupField,
  TextAreaField,
} from "src/inputs/connected-input-fields"
import { bindActions, bindSelectors } from "src/store/utils"
import FormattedTitle from "src/components/FormattedTitle"
import actions from "src/store/actions"
import { required } from "../validators"
import selectors from "src/store/selectors"
import StepNavigators from "src/components/StepNavigators"

const procedureItemFields = [
  "accessRoles",
  "type",
  "icon",
  "title",
  "text",
  "mediaLibrary",
  "draft",
  "viewableByExternals",
  "nextReviewDate",
  "nextReviewDateIsApplicable",
  "contactEmail",
  "reviewDate",
  "classification",
  "editorialVersion",
  "documentOwner"
]
const itemFields = ["changeLog", "howTo", "text", "image"]

export class NotificationForm extends Component {
  state = {
    loading: false,
    publishing: false,
    previewVisible: false,
    previewImage: "",
  }

  handlePublish = () => {
    this.setState({ publishing: true })
    const { item, formValues, update, procedureFormValues } = this.props
    const isMajorUpdate = formValues.updateType === "major" ? true : false
    const mediaFiles = has(procedureFormValues, "procedureMediaFiles.fileList")
      ? procedureFormValues.procedureMediaFiles.fileList
      : []
    const mediaLinks = mediaFiles.map((file) => ({ title: file.name, url: file.url }))

    const formData = {
      ...pick(procedureFormValues, procedureItemFields),
      notification: isMajorUpdate
        ? {
            ...pick(formValues, itemFields),
            image: has(formValues, "image.file") ? formValues.image.file.url : null,
          }
        : null,
      mediaLibrary: [...mediaLinks],
      draftVersion: null,
      steps: procedureFormValues.steps
        .filter((item) => !item.archiveStep)
        .map((item) => ({
          ...item,
          stepId: item.stepId ? (item.stepId.startsWith("temp_") ? null : item.stepId) : null,
        })),
    }

    // take item method from modal or update
    const itemAction = this.props ? this.props.method || update : update
    return itemAction({ opfItem: { ...item, ...formData }, majorUpdate: isMajorUpdate })
      .then((response) => {
        if (isFunction(this.props.callback)) {
          return window.requestAnimationFrame(() => {
            !this.unmounted && this.setState({ publishing: false })
            return this.leaveEditMode({ success: true, response, majorUpdate: isMajorUpdate })
          })
        }
        window.requestAnimationFrame(() => {
          !this.unmounted && this.setState({ publishing: false })
          this.leaveEditMode({ success: true, response, majorUpdate: isMajorUpdate })
        })
      })
      .catch((error) => isFunction(this.props.callback) && this.props.callback({ error }))
  }

  leaveEditMode = ({ success, response, majorUpdate }) => {
    const { closeModal, procedureEditMode, setProcedureEditMode, resizeMenu, destroyForm, form } =
      this.props
    return new Promise((resolve) => {
      this.closed = true
      window.requestAnimationFrame(() => {
        closeModal()
        if (isFunction(this.props.callback)) {
          resolve({ closed: true, success, response, majorUpdate })
          this.props.callback({ closed: true, success, response, majorUpdate })
          return
        }
        if (procedureEditMode) setProcedureEditMode(false)
        destroyForm(form)
        resizeMenu(275)
        destroyForm("procedureForm")
        resolve({ closed: true })
      })
    })
  }

  customUploadRequest = ({ onSuccess, onError, file }) => {
    const { uploadFile, getItemBySlug } = this.props
    const item = this.props.item.opfId ? this.props.item : getItemBySlug(this.props.item.parentSlug)
    return uploadFile({ opfItem: item, file }).then((result) => onSuccess(result, result.media))
  }

  handlePreview = (file) => this.setState({ previewImage: file.thumbUrl, previewVisible: true })

  handleCancelPreview = () => this.setState({ previewVisible: false })

  removeFile = (currentFile) => {
    const { change, formValues } = this.props
    const fileList = formValues.image.fileList.filter((file) => file.uid !== currentFile.uid)
    change("image.fileList", fileList)
  }

  componentDidMount() {
    const { changeForm, procedureEditMode, item, currentRole } = this.props
    const notEditingExistingItem = procedureEditMode !== ACTIONS.EDIT
    // const countryOwnerEditingEnglishVariant =
    //   procedureEditMode === ACTIONS.EDIT &&
    //   item.variantId !== null &&
    //   currentRole.name === ROLES.COUNTRY_PROCESS_OWNER
    // if (countryOwnerEditingEnglishVariant) changeForm("notification", "updateType", "minor")
    if (notEditingExistingItem) changeForm("notification", "updateType", "major")
  }

  componentWillUnmount() {
    this.unmounted = true
    if (this.request && this.request.cancel) this.request.cancel("unmounted")
    isFunction(this.props.callback) && !this.closed && this.props.callback({ closed: true })
  }

  render() {
    const { publishing } = this.state
    const { intl, procedureEditMode, currentRole } = this.props
    const { item, submitting, valid, formValues, mapToMediaLinks } = this.props
    const fileList =
      formValues && formValues.image && formValues.image.fileList.length
        ? mapToMediaLinks(formValues.image.fileList)
        : []
    const publishLabel = intl.formatMessage({ id: "button.publish" })
    // const countryOwnerEditingEnglishVariant =
    //   procedureEditMode === ACTIONS.EDIT &&
    //   item.variantId !== null &&
    //   currentRole.name === ROLES.COUNTRY_PROCESS_OWNER
    const disabled = ![ACTIONS.EDIT, ACTIONS.ADD].includes(procedureEditMode) && !item.new

    return (
      <>
        <Form className="ant-form notification-form">
          <Scrollbars autoHide hideTracksWhenNotNeeded>
            <div className="ant-form__spacing">
              <Field
                disabled={disabled/* || countryOwnerEditingEnglishVariant*/}
                name="updateType"
                component={RadioGroupField}
                validate={[required]}
                button
                label={<FormattedMessage id="form.notification.selectType" />}
                className="radio-group"
              >
                <Radio value="minor">
                  <FormattedMessage id="form.notification.minorChange" />
                  {/* <span> - Do not notify users</span> */}
                  <p>
                    <FormattedMessage id="form.notification.minorChangeDescription" />
                  </p>
                </Radio>
                <Radio value="major">
                  <FormattedMessage id="form.notification.majorChange" />
                  {/* <span> - Notify users</span> */}
                  <p>
                    <FormattedMessage id="form.notification.majorChangeDrescription" />
                  </p>
                </Radio>
              </Field>
              {/* TODO: replace hardcoded locale strings  */}
              {formValues && formValues.updateType === "major" ? (
                <div className="notification-form__details fade-in">
                  <Field
                    name="changeLog"
                    component={InputField}
                    label="What is changed?"
                    placeholder="In this update we changed..."
                    validate={[]}
                  />
                  <Field
                    name="howTo"
                    component={InputField}
                    label="Implementation"
                    placeholder="How should the changes be implemented..."
                    validate={[]}
                  />
                  <Field
                    name="text"
                    className="notification-form__textarea"
                    component={TextAreaField}
                    label="Notes"
                    placeholder="Description..."
                    validate={[]}
                  />
                  <div className="ant-form-item-label">
                    <label>
                      <FormattedMessage id="form.notification.attachImage" />
                    </label>
                  </div>
                  <div className="media-wall">
                    {fileList.map((file, index) => (
                      <div className="media" key={index}>
                        <Tooltip
                          title={
                            <FormattedTitle
                              id="common.remove"
                              values={{
                                target: intl.formatMessage(
                                  { id: "common.image" },
                                  { one: (chunk) => chunk, plural: () => "" }
                                ),
                              }}
                            />
                          }
                        >
                          <Button
                            className="media__delete"
                            size="small"
                            icon={<DeleteOutlined />}
                            shape="circle"
                            onClick={() => this.removeFile(file)}
                          />
                        </Tooltip>
                        <div className="media__image-wrap" onClick={() => this.handlePreview(file)}>
                          {process.env.NODE_ENV === "production" ? (
                            <div
                              className="media__image"
                              style={{ backgroundImage: `url(${file.thumbUrl})` }}
                            />
                          ) : file.thumbUrl ? (
                            <div
                              className="media__image"
                              style={{ backgroundImage: `url(${file.thumbUrl})` }}
                            />
                          ) : (
                            <div className="media__image">
                              <PictureOutlined />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    <Field
                      name="image"
                      className="media-wall__upload-field"
                      component={FileDragAndDropField}
                      customUploadRequest={this.customUploadRequest}
                      acceptedFileType="image/png,image/jpg,image/jpeg,image/gif"
                      limit={1}
                      multiple={false}
                      item={item}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </Scrollbars>
        </Form>
        <StepNavigators className="row">
          <div className="col-xs end-xs">
            <Button
              htmlType="button"
              type="primary"
              onClick={this.handlePublish}
              loading={publishing}
              disabled={submitting || !valid}
            >
              {formValues && formValues.updateType === "major" ? publishLabel : publishLabel}
            </Button>
          </div>
        </StepNavigators>

        <Modal
          className="media-wall-preview-modal"
          open={this.state.previewVisible}
          footer={null}
          onCancel={this.handleCancelPreview}
          centered
          style={{ position: "relative", maxHeight: "96vh", textAlign: "center" }}
        >
          <img alt="example" src={this.state.previewImage} />
        </Modal>
      </>
    )
  }
}

NotificationForm.propTypes = {
  mode: PropTypes.string,
  formValues: PropTypes.object,
  procedureValues: PropTypes.object,
  update: PropTypes.func,
  closeModal: PropTypes.func,
  destroyForm: PropTypes.func,
  getItemBySlug: PropTypes.func,
  uploadFile: PropTypes.func,
  resizeMenu: PropTypes.func,
  procedureEditMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  ...propTypes,
}

const form = "notification"
const ConnectedNotificationForm = reduxForm({ form })(NotificationForm)
const mapStateToProps = bindSelectors({
  formValues: getFormValues(form),
  procedureFormValues: getFormValues("procedureForm"),
  procedureEditMode: selectors.itemSelectors.getProcedureEditModeState,
  mapToMediaLinks: selectors.mapToMediaLinks,
  getItemBySlug: selectors.itemSelectors.getItemBySlug,
  currentRole: selectors.userSelectors.getCurrentRole,
})
const mapDispatchToProps = bindActions({
  changeForm: change,
  update: actions.itemActions.updateItem,
  closeModal: actions.uiActions.closeFormModal,
  setProcedureEditMode: actions.itemActions.setProcedureEditMode,
  resizeMenu: actions.uiActions.resizeMenu,
  uploadFile: actions.itemActions.uploadFile,
  destroyForm: destroy,
})
const IntlNotificationForm = injectIntl(ConnectedNotificationForm)
export default connect(mapStateToProps, mapDispatchToProps)(IntlNotificationForm)

import { Card } from "antd"
import { Field } from "redux-form"
import PropTypes from "prop-types"
import React from "react"

import FormattedTitle from "src/components/FormattedTitle"
import { InputField } from "src/inputs/connected-input-fields"
import { required } from "../../../validators"

export const OpfDocumentInfoStep = (props) => {
  const { cardTitle, cardDescription, children } = props
  return (
    <>
      <br />
      {(cardTitle || cardDescription) && (
        <Card title={cardTitle} bordered={false}>
          {cardDescription}
        </Card>
      )}
      <Field
        name="title"
        component={InputField}
        validate={[required]}
        label={<FormattedTitle id="common.title" />}
      />
      {children && <div>{children}</div>}
    </>
  )
}

OpfDocumentInfoStep.propTypes = {
  cardTitle: PropTypes.any,
  cardDescription: PropTypes.object,
}
export default OpfDocumentInfoStep

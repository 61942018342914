import { Field, getFormValues, propTypes, reduxForm } from "redux-form"
import { FormattedMessage, injectIntl } from "react-intl"
import { Component, lazy } from "react"
import PropTypes from "prop-types"
import { connect, useSelector } from "react-redux"
import { notification } from "antd"
import { pick, uniq } from "lodash"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"

import { ACTIONS, EMPTY_HTML, TREES } from "src/globals"
import { DateField, RichTextField, SwitchField } from "src/inputs/connected-input-fields"
import { bindActions, bindSelectors } from "src/store/utils"

import FormattedTitle from "src/components/FormattedTitle"
import SelectItemTypeStep from "../steps/SelectItemTypeStep"
import OpfDocumentInfoStep from "../steps/opfDocuments/OpfDocumentInfoStep"
import StepHandler from "src/forms/StepHandler"
import actions from "src/store/actions"
import { getItemTypeChildTypes } from "src/services/itemTypes"
import { required } from "../validators"
import selectors from "src/store/selectors"
import { isDocumentCategoryType, isDocumentSubCategoryType } from "src/components/helpers"
import { InputField } from "src/inputs/connected-input-fields"
import { mustBeEmail, mustBeDecimal } from "src/forms/validators"

const RelationsStep = lazy(() =>
  /* webpackPrefetch: true */ import("../steps/opfDocuments/RelationsStep")
)
const MetadataStep = lazy(() => /* webpackPrefetch: true */ import("../steps/MetadataStep"))
const ReviewStep = lazy(() => /* webpackPrefetch: true */ import("../steps/ReviewStep"))
const TranslateStep = lazy(() => /* webpackPrefetch: -3 */ import("../steps/TranslateStep"))

const itemFields = [
  "tree",
  "type",
  "title",
  "viewableByExternals",
  "editorialVersion",
  "documentOwner",
  "nextReviewDate",
  "nextReviewDateIsApplicable",
  "reviewDate",
  "relatedProcesses",
  "relatedDocuments",
  "pdf",
  "text",
]

export const onSubmit = (values, _dispatch, props) => {
  const {
    mode,
    createCategory,
    // createDocumentCategoryVariant,
    // createDocumentSubCategoryVariant,
    createDocumentSubCategory,
    updateDocumentCategory,
    updateDocumentSubCategory,
    translateDocumentCategory,
    translateDocumentSubCategory,
    getRelatedDocumentById,
    getAllRelatedChildPoliciesAndStandards,
    createDocument,
    closeModal,
    intl,
    item,
  } = props

  const expandRelatedDocuments = (item, relatedDocuments) => {
    return relatedDocuments
      ? uniq(
          relatedDocuments
            .map(getRelatedDocumentById)
            .flatMap(getAllRelatedChildPoliciesAndStandards)
            .map((item) => item.opfId)
            .filter((id) => id !== item.opfId)
        )
      : []
  }

  const formData = {
    ...pick(values, itemFields),
    tree: item.tree,
    relatedDocuments: expandRelatedDocuments(item, values.relatedDocuments),
  }

  let submission
  switch (mode) {
    case ACTIONS.ADD_DOCUMENT_CATEGORY:
      submission = createCategory({
        opfDocument: {
          ...formData,
        },
      })
      break
    // case ACTIONS.CREATE_CATEGORY_VARIANT:
    //   if (isDocumentCategoryType(item.type)) {
    //     submission = createDocumentCategoryVariant({ opfDocument: { ...item, ...formData } })
    //   } else if (isDocumentSubCategoryType(item.type)) {
    //     submission = createDocumentSubCategoryVariant({ opfDocument: { ...item, ...formData } })
    //   }
    //   break
    case ACTIONS.ADD:
      submission = createDocument({
        opfDocument: {
          ...formData,
          parentId: item.opfId,
        },
      })
      break
    case ACTIONS.ADD_DOCUMENT_SUBCATEGORY:
      submission = createDocumentSubCategory({
        opfDocument: {
          ...formData,
          parentId: item.opfId,
        },
      })
      break
    case ACTIONS.EDIT:
      if (isDocumentCategoryType(item.type)) {
        submission = updateDocumentCategory({ opfDocument: { ...item, ...formData } })
      } else if (isDocumentSubCategoryType(item.type)) {
        submission = updateDocumentSubCategory({ opfDocument: { ...item, ...formData } })
      }
      break
    case ACTIONS.TRANSLATE:
      if (isDocumentCategoryType(item.type)) {
        submission = translateDocumentCategory({ opfDocument: { ...item, ...formData } })
      } else if (isDocumentSubCategoryType(item.type)) {
        submission = translateDocumentSubCategory({ opfDocument: { ...item, ...formData } })
      }
      break
    default:
      submission = Promise.resolve()
      break
  }
  return submission.then(closeModal).then(() => {
    const message = intl.formatMessage({ id: "message.savedChanges" })
    notification.success({ message })
  })
}

const DocumentMetaFields = injectIntl((props) => {
  const formValues = useSelector(getFormValues(form))

  const { intl, item, mode, ...rest } = props
  return (
    <>
      <MetadataStep {...rest}>
        <div className="row form-wizard__section">
          <span>
            <FormattedTitle id="common.reviewDate" />
          </span>
          <div className="row">
            <div style={{ marginTop: 20, marginRight: 15 }}>
              <Field
                name="nextReviewDateIsApplicable"
                component={SwitchField}
                disableValidation
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </div>
            {formValues && !formValues.nextReviewDateIsApplicable ? (
              <Tooltip title={intl.formatMessage({ id: "common.notApplicable" })}>
                <div>
                  <Field
                    name="nextReviewDateDisabled"
                    key="nextReviewDateDisabled"
                    component={DateField}
                    disabled
                    mode="day"
                  />
                </div>
              </Tooltip>
            ) : (
              <Field
                name="nextReviewDate"
                component={DateField}
                defaultValue={item ? item.nextReviewDate : null}
              />
            )}
          </div>
        </div>

        <div className="row form-wizard__section">
          <div className="ant-form-item-label ant-form-item-label--fluid">
            <span>
              <FormattedTitle id="common.editorialVersion" />
            </span>
          </div>
          <Field
            name="editorialVersion"
            component={InputField}
            validate={[mustBeDecimal]}
            disabled
          />
        </div>

        <div className="row form-wizard__section">
          <div className="ant-form-item-label ant-form-item-label--fluid">
            <span>
              <FormattedTitle id="common.documentOwnerEmailAddress" />
            </span>
          </div>
          <Field
            name="documentOwner"
            component={InputField}
          />
        </div>
      </MetadataStep>
    </>
  )
})

const DocumentsTitleDescriptionFields = injectIntl((props) => {
  const { intl, item, customUploadRequest, ...rest } = props
  return (
    <div>
      <OpfDocumentInfoStep {...rest}>
        <Field
          name="text"
          component={RichTextField}
          validate={[required]}
          className="area"
          label={<FormattedTitle id="common.description" />}
        />
      </OpfDocumentInfoStep>
    </div>
  )
})

export class DocumentCategoryForm extends Component {
  validate = () => [...itemFields, "newOrder"].forEach(this.props.touch)

  getCreateCategoryVariantSteps = () => {
    const { item } = this.props

    const categoryVariantSteps = [
      {
        title: "form.step.title.title",
        component: DocumentsTitleDescriptionFields,
        props: {
          item,
          cardTitle: <FormattedMessage id="form.documents.category.add.title" />,
          cardDescription: (
            <FormattedMessage id={`form.documents.category.${item.tree}.add.description`} />
          ),
        },
      },
    ]
    return categoryVariantSteps
  }

  getTranslateCategorySteps = () => {
    const { item } = this.props

    const translateCategorySteps = [
      {
        title: "form.step.title.title",
        component: TranslateStep,
        props: {
          item,
          cardTitle: <FormattedMessage id="form.documents.category.add.title" />,
          cardDescription: <FormattedMessage id="form.documents.category.translate.description" />,
        },
      },
    ]
    return translateCategorySteps
  }

  getAddCategorySteps = () => {
    const { item, intl, configurator } = this.props
    const titleAndDescriptionProps =
      configurator === null
        ? {
            cardTitle: <FormattedMessage id="form.step.titleAndDescription.eng.title" />,
            cardDescription: (
              <FormattedMessage
                id="form.step.titleAndDescription.eng.description"
                values={{ itemType: () => intl.formatMessage({ id: "common.item" }) }}
              />
            ),
          }
        : {}

    const addCategorySteps = [
      {
        title: "form.step.titleAndDescription.title",
        component: DocumentsTitleDescriptionFields,
        props: { item, titleAndDescriptionProps },
      },
    ]
    return addCategorySteps
  }

  getAddDocumentSteps = () => {
    const { item, intl, configurator } = this.props

    // ? ⛔ If the user is a GPSO show message regarding EN creation of P/S
    const titleAndDescriptionProps =
      configurator === null
        ? {
            cardTitle: <FormattedMessage id="form.step.titleAndDescription.eng.title" />,
            cardDescription: (
              <FormattedMessage
                id="form.step.titleAndDescription.eng.description"
                values={{ itemType: () => intl.formatMessage({ id: "common.item" }) }}
              />
            ),
          }
        : {}

    const selectPolicyStandardTypeStep = {
      title: "form.step.selectType.title",
      component: SelectItemTypeStep,
      props: { item },
    }

    const addDocumentSteps = [
      {
        title: "form.step.titleAndDescription.title",
        component: DocumentsTitleDescriptionFields,
        props: titleAndDescriptionProps,
      },
      {
        title: "form.step.relations.title",
        component: RelationsStep,
        props: {
          cardTitle: <FormattedMessage id="form.step.relations.intro.title" />,
          cardDescription: <FormattedMessage id="form.step.relations.intro.description" />,
        },
      },
      {
        title: "form.step.setMetadata.title",
        component: DocumentMetaFields,
        props: {
          item,
          cardTitle: null,
          cardDescription: null,
        },
      },
    ]

    if (item.tree === TREES.POLICIES_STANDARDS) {
      addDocumentSteps.unshift(selectPolicyStandardTypeStep)
    }

    return addDocumentSteps
  }

  getEditSteps = () => {
    const { item } = this.props
    const editSteps = [
      {
        title: "form.step.title.title",
        component: DocumentsTitleDescriptionFields,
        props: {
          item,
          cardTitle: <FormattedMessage id="form.documents.category.add.title" />,
          cardDescription: (
            <FormattedMessage id={`form.documents.category.${item.tree}.add.description`} />
          ),
        },
      },
    ]
    return editSteps
  }

  componentDidMount() {
    const { item, initialize, mode } = this.props
    const modesWithInitialValues = [
      ACTIONS.EDIT,
      // ACTIONS.CREATE_VARIANT,
      // ACTIONS.CREATE_CATEGORY_VARIANT,
      ACTIONS.TRANSLATE,
    ]
    if (mode === ACTIONS.ADD) {
      const childTypes = getItemTypeChildTypes(item.type)
      let type
      if (childTypes.length === 1) {
        const [childType] = childTypes
        type = childType
      }
      initialize({
        type,
        viewableByExternals: false,
        relatedProcesses: [],
        relatedDocuments: [],
        editorialVersion: "1.0",
        nextReviewDateIsApplicable: true,
      })
    }
    if (modesWithInitialValues.includes(mode) && item) {
      const values = pick(item, itemFields)
      initialize({
        ...values,
        text: item.text === "" ? EMPTY_HTML : item.text,
      })
    }
  }

  render() {
    const { valid, mode, formValues, submit, submitting, intl } = this.props
    let steps = []
    switch (mode) {
      case ACTIONS.ADD_DOCUMENT_CATEGORY:
      case ACTIONS.ADD_DOCUMENT_SUBCATEGORY:
        steps = this.getAddCategorySteps()
        break
      case ACTIONS.ADD:
        steps = this.getAddDocumentSteps()
        break
      // case ACTIONS.CREATE_CATEGORY_VARIANT:
      //   steps = this.getCreateCategoryVariantSteps()
      //   break
      case ACTIONS.EDIT:
        steps = this.getEditSteps()
        break
      case ACTIONS.TRANSLATE:
        steps = this.getTranslateCategorySteps()
        break
      default: // fallthrough
    }
    if ([ACTIONS.ADD].includes(mode)) {
      steps = [
        ...steps,
        {
          title: "form.step.review.title",
          component: ReviewStep,
          props: { value: formValues, intl: intl },
        },
      ]
    }
    return (
      <StepHandler
        onFinalStepSubmit={submit}
        steps={steps}
        overview={mode}
        valid={valid}
        validate={this.validate}
        submitting={submitting}
      />
    )
  }
}

DocumentCategoryForm.propTypes = {
  mode: PropTypes.string,
  formValues: PropTypes.object,
  getUserItemRights: PropTypes.func,
  mapToMediaLinks: PropTypes.func,
  createCategory: PropTypes.func,
  // createDocumentCategoryVariant: PropTypes.func,
  // createDocumentSubCategoryVariant: PropTypes.func,
  updateDocumentCategory: PropTypes.func,
  updateDocumentSubCategory: PropTypes.func,
  translateDocumentCategory: PropTypes.func,
  create: PropTypes.func,
  reorder: PropTypes.func,
  closeModal: PropTypes.func,
  fetchDocumentCategories: PropTypes.func,
  ...propTypes,
}
const form = "documentCategory"
const ConnectedDocumentCategoryForm = reduxForm({ form, onSubmit })(DocumentCategoryForm)
const mapStateToProps = bindSelectors({
  formValues: getFormValues(form),
  configurator: selectors.userSelectors.getCurrentConfigurator,
  getUserItemRights: selectors.userSelectors.getUserItemRights,
  mapToMediaLinks: selectors.mapToMediaLinks,
  getRelatedDocumentById: selectors.documentsSelectors.getRelatedDocumentById,
  getAllRelatedChildPoliciesAndStandards:
    selectors.documentsSelectors.getAllRelatedChildPoliciesAndStandards,
})
const mapDispatchToProps = bindActions({
  createCategory: actions.documentsActions.createDocumentCategory,
  // createDocumentCategoryVariant: actions.documentsActions.createDocumentCategoryVariant,
  // createDocumentSubCategoryVariant: actions.documentsActions.createDocumentSubCategoryVariant,
  createDocumentSubCategory: actions.documentsActions.createDocumentSubCategory,
  updateDocumentCategory: actions.documentsActions.updateDocumentCategory,
  updateDocumentSubCategory: actions.documentsActions.updateDocumentSubCategory,
  translateDocumentCategory: actions.documentsActions.translateDocumentCategory,
  translateDocumentSubCategory: actions.documentsActions.translateDocumentSubCategory,
  createDocument: actions.documentsActions.createDocument,
  reorder: actions.itemActions.reorderItems,
  closeModal: actions.uiActions.closeFormModal,
})
const IntlDocumentCategoryForm = injectIntl(ConnectedDocumentCategoryForm)
export default connect(mapStateToProps, mapDispatchToProps)(IntlDocumentCategoryForm)

// where to find: https://localhost:44309/policies-standards/group-legal
// click on add -> Policy or Standard, modal will show

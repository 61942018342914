import { Form, Button } from "antd"
import React, { Component, Suspense, lazy } from "react"
import { change, getFormValues, propTypes, reduxForm } from "redux-form"
import { has, without } from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"

import { bindActions, bindSelectors } from "src/store/utils"
import FormattedTitle from "src/components/FormattedTitle"
import Loading from "src/components/Loading"
import actions from "src/store/actions"
import { linkToFile } from "src/components/helpers"
import selectors from "src/store/selectors"
import StepNavigators from "src/components/StepNavigators"
import Tabs from "src/components/Tabs"

const VideosStep = lazy(() => import("../steps/VideosStep"))
const PicturesStep = lazy(() => import("../steps/PicturesStep"))
const DocumentsStep = lazy(() => import("../steps/DocumentsStep"))
const LinksStep = lazy(() => import("../steps/LinksStep"))

export class ProcedureToolsForm extends Component {
  handleInitialize() {
    const { item, initialize } = this.props
    const { links, mediaLibrary } = item

    const internalLinks = links ? links.filter((link) => link.internal) : []
    const externalLinks = without(links, ...internalLinks)
    const documentsFileList = internalLinks.map(linkToFile).filter(Boolean)
    const internalMedia = mediaLibrary
      ? mediaLibrary.filter((media) => media.internal !== false)
      : []
    const externalMedia = without(mediaLibrary, ...internalMedia)
    const mediaFileList = internalMedia.map(linkToFile).filter(Boolean)

    const initData = {
      ...item,
      links: externalLinks,
      documents: { fileList: documentsFileList },
      mediaFiles: { fileList: mediaFileList },
      videos: externalMedia,
    }
    initialize(initData)
  }

  componentDidMount() {
    const { item, change } = this.props
    const { links } = item
    const fileList = links
      ? links
          .filter((link) => link.internal)
          .map(linkToFile)
          .filter(Boolean)
      : []

    this.handleInitialize()

    fileList.forEach((file) => {
      change(`documentNames.${file.uid}.name`, file.name)
    })
  }

  customUploadRequest = ({ onSuccess, onError, file }) => {
    const { uploadFile, getItemBySlug } = this.props
    const item = this.props.opfId ? this.props.item : getItemBySlug(this.props.item.parentSlug)
    return uploadFile({ opfItem: item, file }).then((result) => onSuccess(result, result.media))
  }

  removeAttachment = (fileListType, uid) => {
    const { change, formValues } = this.props
    const fileList = formValues[fileListType].fileList.filter((file) => file.uid !== uid)

    change(fileListType, { ...formValues[fileListType], fileList })
  }

  moveAttachmentUp = (fileListType, currentIndex) => {
    if (currentIndex === 0) return null

    const { change, formValues } = this.props

    const fileList = [...formValues[fileListType].fileList]
    const newIndex = currentIndex - 1
    const _file = fileList[newIndex]
    fileList[newIndex] = fileList[currentIndex]
    fileList[currentIndex] = _file

    change(fileListType, { ...formValues[fileListType], fileList })
  }

  submitStep = () => {
    const { closeModal, formValues, currentIndex, changeExternalForm } = this.props
    const documentNames = formValues.documentNames
    const mediaFiles = has(formValues, "mediaFiles.fileList") ? formValues.mediaFiles.fileList : []
    const videos = has(formValues, "videos") ? formValues.videos : []
    const links = formValues.links ? formValues.links : []
    const documentFiles = has(formValues, "documents.fileList") ? formValues.documents.fileList : []

    const getUpdatedDocumentName = (file) => {
      const updatedDocument = documentNames ? documentNames[file.uid] : null
      return updatedDocument ? updatedDocument.name : file.response.media.name
    }

    const documentLinks = documentFiles.map((file) => ({
      title: getUpdatedDocumentName(file),
      url: file.url,
      internal: true,
    }))

    const mediaLinks = mediaFiles.map((file) => ({
      title: file.name,
      url: file.url,
      thumbUrl: file.thumbUrl,
      type: file.type,
      internal: true,
    }))

    const videoLinks = videos.map((video) => ({
      ...video,
      type: "video",
      thumbnail: video.thumbnail,
      internal: false,
    }))

    const formData = {
      links: [...links.map((link) => ({ ...link, external: true })), ...documentLinks],
      mediaLibrary: [...mediaLinks, ...videoLinks],
    }

    changeExternalForm("procedureForm", `steps[${currentIndex}].links`, formData.links)
    changeExternalForm(
      "procedureForm",
      `steps[${currentIndex}].mediaLibrary`,
      formData.mediaLibrary
    )
    closeModal()
  }

  render() {
    const { formValues, mapToMediaLinks, handleSubmit, currentStep, hasFastConnection } = this.props
    const mediaFiles =
      formValues && formValues.mediaFiles ? mapToMediaLinks(formValues.mediaFiles.fileList) : null
    const documents =
      formValues && formValues.documents ? mapToMediaLinks(formValues.documents.fileList) : null

    const items = [
      {
        key: "1",
        label: (
          <FormattedTitle id="common.image" values={{ one: () => "", plural: (chunk) => chunk }} />
        ),
        forceRender: hasFastConnection,
        children: (
          <Suspense fallback={<Loading />}>
            <PicturesStep
              mediaFiles={mediaFiles}
              removeAttachment={this.removeAttachment}
              moveAttachmentUp={this.moveAttachmentUp}
              customUploadRequest={this.customUploadRequest}
            />
          </Suspense>
        ),
      },
      {
        key: "2",
        label: (
          <FormattedTitle id="common.video" values={{ one: () => "", plural: (chunk) => chunk }} />
        ),
        forceRender: hasFastConnection,
        children: (
          <Suspense fallback={<Loading />}>
            <VideosStep />
          </Suspense>
        ),
      },
      {
        key: "3",
        label: (
          <FormattedTitle
            id="common.document"
            values={{ one: () => "", plural: (chunk) => chunk }}
          />
        ),
        forceRender: hasFastConnection,
        children: (
          <Suspense fallback={<Loading />}>
            <DocumentsStep
              documents={documents}
              removeAttachment={this.removeAttachment}
              moveAttachmentUp={this.moveAttachmentUp}
              customUploadRequest={this.customUploadRequest}
            />
          </Suspense>
        ),
      },
      {
        key: "4",
        label: (
          <FormattedTitle id="common.link" values={{ one: () => "", plural: (chunk) => chunk }} />
        ),
        forceRender: hasFastConnection,
        children: (
          <Suspense fallback={<Loading />}>
            <LinksStep />
          </Suspense>
        ),
      },
    ]

    return (
      <>
        <Form>
          <Tabs animated defaultActiveKey={currentStep.toString()} items={items} />
        </Form>
        <StepNavigators className="row">
          <div className="col-xs end-xs">
            <Button htmlType="button" onClick={handleSubmit(this.submitStep)} type="primary">
              <FormattedTitle id="common.confirm" />
            </Button>
          </div>
        </StepNavigators>
      </>
    )
  }
}

ProcedureToolsForm.propTypes = {
  mode: PropTypes.string,
  formValues: PropTypes.object,
  getItemBySlug: PropTypes.func,
  mapToMediaLinks: PropTypes.func,
  translate: PropTypes.func,
  closeModal: PropTypes.func,
  uploadFile: PropTypes.func,
  ...propTypes,
}
const form = "procedureTools"
const ConnectedProcedureToolsForm = reduxForm({ form })(ProcedureToolsForm)
const mapStateToProps = bindSelectors({
  formValues: getFormValues(form),
  getItemBySlug: selectors.itemSelectors.getItemBySlug,
  mapToMediaLinks: selectors.mapToMediaLinks,
  currentStep: selectors.uiSelectors.getCurrentStep,
  hasFastConnection: selectors.uiSelectors.getConnectionIsFast,
})
const mapDispatchToProps = bindActions({
  translate: actions.itemActions.translateItem,
  closeModal: actions.uiActions.closeFormModal,
  uploadFile: actions.itemActions.uploadFile,
  changeExternalForm: change,
})
const IntlProcedureToolsForm = injectIntl(ConnectedProcedureToolsForm)
export default connect(mapStateToProps, mapDispatchToProps)(IntlProcedureToolsForm)

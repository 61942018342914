import React, { Component, Suspense, lazy } from "react"
import { getFormValues, propTypes, reduxForm } from "redux-form"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { notification } from "antd"
import { pick } from "lodash"

import { bindActions, bindSelectors } from "src/store/utils"
import { removeFileExtension, sanitizeHTML } from "src/components/helpers"
import { ACTIONS } from "../../globals"
import Loading from "src/components/Loading"
import { OpfItemShape } from "src/components/opf-prop-types"
import StepHandler from "../StepHandler"
import actions from "src/store/actions"

const SetOrderStep = lazy(() => /* webpackPrefetch: -5 */ import("../steps/SetOrderStep"))
const TranslateStep = lazy(() => /* webpackPrefetch: -6 */ import("../steps/TranslateStep"))

const itemRichTextFields = ["text"]
const itemFields = [
  "type",
  "title",
  "draft",
  "viewableByExternals",
  "edirotialVersion",
  "nextReviewDate",
  "contactEmail",
  "reviewDate",
  ...itemRichTextFields,
]

const onSubmit = (values, dispatch, props) => {
  const { mode, closeModal, translate, intl, item } = props
  const { documentNames } = values
  const getUpdatedDocumentName = (file) => {
    const updatedDocument = documentNames ? documentNames[removeFileExtension(file.uid)] : null
    return updatedDocument ? updatedDocument.name : file.response.media.name
  }
  const { documents = { fileList: [] }, links = [] } = values
  const documentLinks = documents.fileList.map((file) => ({
    title: getUpdatedDocumentName(file),
    url: file.url,
    internal: true,
  }))
  const formData = {
    ...pick(values, itemFields),
    links: [...links.map((link) => ({ ...link, external: true })), ...documentLinks],
  }
  let submission
  switch (mode) {
    case ACTIONS.TRANSLATE:
      submission = translate({ opfItem: { ...item, ...formData } })
      break
    default:
      submission = Promise.resolve()
      break
  }
  return submission.then(closeModal).then(() => {
    const message = intl.formatMessage({ id: "message.savedChanges" })
    const translateProcedureDescription = intl.formatMessage({
      id: "message.translateProcedureDescription",
    })
    if (mode === ACTIONS.TRANSLATE) {
      notification.info({
        message: intl.formatMessage({ id: "message.translateProcedureTitle" }),
        description: <p dangerouslySetInnerHTML={sanitizeHTML(translateProcedureDescription)} />,
        duration: 12,
      })
    } else {
      notification.success({ message })
    }
  })
}

export class ProcedureForm extends Component {
  render() {
    const { item, valid, mode, submit, submitting } = this.props
    return (
      <Suspense fallback={<Loading />}>
        <StepHandler
          onFinalStepSubmit={submit}
          steps={
            mode === ACTIONS.TRANSLATE
              ? [
                  {
                    title: "form.step.translate.title",
                    component: TranslateStep,
                    props: { item },
                  },
                ]
              : [
                  {
                    title: "form.step.setOrder.title",
                    component: SetOrderStep,
                    props: { parentItem: item },
                  },
                ]
          }
          overview={mode !== ACTIONS.TRANSLATE}
          valid={valid}
          validate={() => itemFields.forEach(this.props.touch)}
          submitting={submitting}
        />
      </Suspense>
    )
  }
}

ProcedureForm.propTypes = {
  mode: PropTypes.string,
  item: OpfItemShape,
  formValues: PropTypes.object,
  reorder: PropTypes.func,
  translate: PropTypes.func,
  closeModal: PropTypes.func,
  ...propTypes,
}
const form = "procedure"
const ConnectedProcedureForm = reduxForm({ form, onSubmit })(ProcedureForm)
const mapStateToProps = bindSelectors({
  formValues: getFormValues(form),
})
const mapDispatchToProps = bindActions({
  reorder: actions.itemActions.reorderItems,
  translate: actions.itemActions.translateItem,
  closeModal: actions.uiActions.closeFormModal,
})
const IntlProcedureForm = injectIntl(ConnectedProcedureForm)
export default connect(mapStateToProps, mapDispatchToProps)(IntlProcedureForm)

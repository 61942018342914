import React from "react"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { Form, Card, Popover, Radio } from "antd"
import { FormattedMessage, useIntl } from "react-intl"
import { Field } from "redux-form"
import titleize from "titleize"

import { OPF_DOCUMENT_TYPES, OPF_ITEM_TYPES } from "../../../globals"
import {
  getItemTypeChildTypes,
  getTypeHelpMeDecideTooltip,
  getTypeIntlKey,
} from "../../../services/itemTypes"
import { OpfItemShape } from "src/components/opf-prop-types"
import OpfItemSvg from "src/assets/images/opfItemSvg.svg"
import { RadioGroupField } from "src/inputs/connected-input-fields"
import { enumarableText } from "src/components/helpers"
import opfCategorySvg from "src/assets/images/opfCategorySvg.svg"
import { required } from "../../validators"

export const valueToFormOption = (value) => ({ value, label: titleize(value) })

SelectItemTypeStep.propTypes = { item: OpfItemShape }

export default function SelectItemTypeStep(props) {
  const intl = useIntl()
  const { item } = props
  const childTypeOptions = getItemTypeChildTypes(item.type)
  const descriptionChildTypes = enumarableText(
    intl,
    childTypeOptions.map(getTypeIntlKey),
    "common.or"
  )
  const optionValue = (option) => {
    switch (option.value) {
      case OPF_ITEM_TYPES.PROCEDURE:
      case OPF_ITEM_TYPES.PROCESS:
        return OpfItemSvg
      case OPF_ITEM_TYPES.PROCESS_CATEGORY:
      case OPF_ITEM_TYPES.PROCEDURE_CATEGORY:
        return opfCategorySvg
      case OPF_DOCUMENT_TYPES.POLICY:
        return opfCategorySvg
      case OPF_DOCUMENT_TYPES.STANDARD:
        return OpfItemSvg
      default: // fallthrough
    }
  }

  return (
    <Form.Item className="fade-in">
      <div>
        <Card bordered={false} title={<FormattedMessage id="form.step.selectType.title" />}>
          <FormattedMessage
            id="form.step.selectType.description"
            values={{
              childTypes: () => descriptionChildTypes,
              strong: () => <strong>{item.title}</strong>,
            }}
          />
        </Card>
      </div>
      <br />
      <div className="center-xs">
        <Radio.Group>
          <Field validate={[required]} component={RadioGroupField} button name="type">
            {childTypeOptions.map(valueToFormOption).map((option) => (
              <Radio.Button key={option.value} value={option.value} className="item-type">
                <img src={optionValue(option)} alt="" />
                <p>{option.label}</p>
              </Radio.Button>
            ))}
          </Field>
        </Radio.Group>
        <div className="center-xs">
          <Popover
            placement="bottom"
            trigger={["hover", "click"]}
            content={
              <FormattedMessage
                id={getTypeHelpMeDecideTooltip(item.type)}
                values={{
                  strong: (chunk) => <strong>{chunk}</strong>,
                  vSpace: () => (
                    <span
                      style={{
                        display: "block",
                        height: "10px",
                      }}
                    />
                  ),
                }}
              />
            }
            overlayStyle={{ width: "500px", maxWidth: "80%" }}
          >
            <QuestionCircleOutlined /> <FormattedMessage id="guide.helpMeDecideType" />
          </Popover>
        </div>
      </div>
    </Form.Item>
  )
}

// where to find: https://localhost:44309/processes/planningprocesses click on add, modal will show, click/hover on help me decide

import { ArrowLeftOutlined } from "@ant-design/icons"
import { Button, Steps } from "antd"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import { TabletBreakpoint } from "src/components/breakpoints"
import actions from "src/store/actions"
import selectors from "src/store/selectors"
import useActions from "src/hooks/useActions"
import StepNavigators from "src/components/StepNavigators"

const { Step } = Steps

StepButtons.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.any),
  valid: PropTypes.bool,
  submit: PropTypes.func,
  submitting: PropTypes.bool,
  validate: PropTypes.func,
}

export default function StepButtons(props) {
  const currentStep = useSelector(selectors.uiSelectors.getCurrentStep)
  const [goToNextStep, goToPreviousStep] = useActions([
    actions.uiActions.nextStep,
    actions.uiActions.previousStep,
  ])
  const { steps, valid, validate, submit, submitting } = props
  const previousButton = currentStep !== 0 && (
    <Button htmlType="button" onClick={goToPreviousStep} className="back-btn">
      <ArrowLeftOutlined />
      <span>
        <FormattedMessage id="button.back" />
      </span>
    </Button>
  )
  const nextButton =
    currentStep + 1 === steps.length ? (
      <Button
        htmlType="button"
        onClick={submitting ? null : submit}
        loading={submitting}
        type="primary"
        disabled={valid !== true}
      >
        <span>
          <FormattedMessage id="button.save" />
        </span>
      </Button>
    ) : (
      <Button
        htmlType="button"
        onClick={valid ? goToNextStep : validate}
        type="primary"
        disabled={valid !== true}
      >
        <span>
          <FormattedMessage id="button.next" />
        </span>
      </Button>
    )

  return (
    <StepNavigators className="row">
      <TabletBreakpoint>
        {(matches) =>
          matches ? (
            <>
              <div className="col-xs-6">{previousButton}</div>
              <div className="col-xs-6 end-xs">{nextButton}</div>
            </>
          ) : (
            <>
              <div className="col-xs">{previousButton}</div>
              <div className="col-xs center-xs">
                <div className="form-step-overview form-step-overview--mobile">
                  <Steps current={currentStep} labelPlacement="vertical" progressDot>
                    {steps.map((step, index) => (
                      <Step key={index} />
                    ))}
                  </Steps>
                </div>
              </div>
              <div className="col-xs end-xs">{nextButton}</div>
            </>
          )
        }
      </TabletBreakpoint>
    </StepNavigators>
  )
}

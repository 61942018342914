import React, { Suspense } from "react"
import { Form } from "antd"
import PropTypes from "prop-types"
import { Scrollbars } from "react-custom-scrollbars"
import { useSelector } from "react-redux"

import Loading from "src/components/Loading"
import StepButtons from "../StepButtons"
import StepOverview from "../StepOverview"
import selectors from "src/store/selectors"

StepHandler.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  onFinalStepSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  validate: PropTypes.func,
  overview: PropTypes.any,
}

/** Handler which takes a collection of steps, handles validation before continuing through
 * and calls submit function when last step is complete and valid */
export default function StepHandler({
  steps,
  onFinalStepSubmit,
  overview,
  submitting,
  valid,
  validate,
}) {
  const currentStep = useSelector(selectors.uiSelectors.getCurrentStep)
  const stepOverview = overview ? <StepOverview currentStep={currentStep} steps={steps} /> : null
  const finalStep = steps.length + 1
  const CurrentStepComponent = steps[currentStep] ? steps[currentStep].component : null
  const currentStepProps = steps[currentStep] ? steps[currentStep].props : {}
  const onSubmit =
    currentStep === finalStep && valid ? onFinalStepSubmit : (event) => event.preventDefault()
  return (
    <>
      {steps.length > 1 ? stepOverview : null}
      <Form onSubmit={onSubmit}>
        <Scrollbars autoHide hideTracksWhenNotNeeded>
          <Suspense fallback={<Loading />}>
            {steps[currentStep] ? (
              <div className="form-step">
                <CurrentStepComponent {...currentStepProps} />
              </div>
            ) : null}
          </Suspense>
        </Scrollbars>
      </Form>
      <StepButtons
        valid={valid}
        validate={validate}
        steps={steps}
        submit={onFinalStepSubmit}
        submitting={submitting}
      />
    </>
  )
}

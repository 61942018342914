import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import React from "react"
import { Steps } from "antd"

import { TabletBreakpoint } from "src/components/breakpoints"
const { Step } = Steps

StepOverview.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.any,
      content: PropTypes.any,
      props: PropTypes.object,
    })
  ),
}

export default function StepOverview({ steps, currentStep }) {
  return (
    <TabletBreakpoint>
      <div className="form-step-overview">
        <Steps current={currentStep} labelPlacement="vertical">
          {steps.map((step, index) => (
            <Step key={index} title={<FormattedMessage id={step.title} />} />
          ))}
        </Steps>
      </div>
    </TabletBreakpoint>
  )
}
